export const HTTP_OK = 200;
export const SUCCESS_NO_CONTENT = 204;
export const NOT_AUTHORIZED = 401;
export const NOT_FOUND = 404;
export const API_TIMEOUT_ERROR = 'ECONNABORTED';
export const ERROR = 400;
export const FORBIDDEN_ERROR = 403;
export const INTERNAL_SERVER_ERROR = 500;
export const TOKEN_EXPIRY_TIME = 'TOKEN_EXPIRY_TIME';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const USER_INFO = 'USER_INFO';
export const USER_ID = 'USER_ID';
export const API_TIMEOUT = 'API_TIMEOUT';
export const SYNC_INTERVAL = 'SYNC_INTERVAL';
export const BACKGROUND_TIMER = 'BACKGROUND_TIMER';
export const BACKGROUND_LOCAL_AUTH_TIME = 180000; //miliseconds //the amount of time app being in background to ask for local authorization
export const PUSH_NOTIFICATION_TOKEN = 'PUSH_NOTIFICATION_TOKEN';
export const TRUE = 'true';
export const FALSE = 'false';
export const ACTION = 'Action';
export const ACTIVE_INDEX = 'Active_Index';
export const OTHERS = 'Others';
export const TA = 'TA';
export const DA = 'DA';
export const NO_PERMISSION = 'NO_PERMISSION';
export const SYNC_INTERVAL_TIME = 300000;
export const USER_APP_SETTINGS = 'USER_APP_SETTINGS';
export const USER_ROLE_MR = 'MR';
export const USER_ROLE_FLM = 'FLM';
export const USER_ROLE_SLM = 'SLM';
export const STP_STATUS_NA = 'NA';
export const USER_ROLE_ADMIN = 'Admin';

export const App_State = {
  ACTIVE: 'active',
  BACKGROUND: 'background',
};
export const NUMBER_1 = 1;
export const NUMBER_2 = 2;
export const NUMBER_3 = 3;

export const PLAN_AND_MEET_PAGE_SIZE = 10;

export const CMS_SUMMARY = {
  PENDING: 'Pending',
  MERGED: 'Merged',
  FAILED: 'Failed',
  DELETED: 'Deleted',
};
export const VISIT_TYPE = {
  UPCOMING: 'Upcoming',
  NOTSUBMITTED: 'DCR Not Submitted',
  POB: 'POB',
  NMNE: 'NMNE',
  RCPA: 'RCPA',
};
export const lockLevelStatus = {
  motherBrand: 1,
  subBrand: 2,
  sku: 3,
};

export const VISIT_STATUS = {NOT_STARTED: 0, IN_PROGRESS: 1, COMPLETED: 2};

export const BACKGROUND_TASK = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  CONFLICT: 'CONFLICT',
  TASK_NAME: 'BACKGROUND_TASK', //Task Name for running background task
  ON_DEMAND_TASK_NAME: 'ON_DEMAND_TASK',
  CMS_SYNC_TASK_NAME: 'CMS_SYNC_TASK',
  NOT_RUNNING: 'NOT_RUNNING',
  NOT_STARTED: 'NOT_STARTED',
  RUNNING: 'RUNNING',
  SYNC_FLEX_TIME: 120, //seconds    //The amount of flex time in seconds before syncInterval that you permit for the sync to take place. Must be less than syncInterval
  SYNC_INTERVAL: 1200, //seconds   //The amount of time in seconds that you wish to elapse between periodic syncs
};

export const TOAST_TYPES = {
  SUCCESS: 'success',
  NOTIFICATION: 'notification',
  WARNING: 'warning',
  ALERT: 'alert',
  STANDARD: 'standard',
  CONFIRM: 'confirm',
};

export const HTTP_PATCH_CODE = {
  VALIDATED: 400,
  ALREADY_EXITS: '103',
  PATCH_EXITS_FOR_OTHER_DAY: '107',
  PATCH_EXHAUSTED: '111',
  VISIT_FOR_2_DOC: '113',
  VISIT_FOR_3_DOC: '114',
  VISIT_FOR_4_DOC: '115',
  NO_OF_PATCHES_MORE: '104',
  MAX_EX_STATION_LIMIT_EXCEEDED: '149',
  PATCH_ALREADY_EXIST_FOR_DAY: '159',
};

export const DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const DIVISION_COLOR = {
  CORE: 'core',
  KYC: 'kyc',
  A_PLUS: 'a+',
  APLUS: 'aplus',
  B: 'b',
  A: 'a',
  C: 'c',
  D: 'd',
  CAMPAIGN: 'campaign',
  RCPA: 'rcpa',
  ADOPTED: 'leading',
  HOSPITAL: 'hospital',
};

export const HOSPITAL_TYPES = {
  H: 'H',
  CH: 'CH',
};

export const TOUR_PLAN = 'TourPlanLanding';
export const ROUTE_EDETAILING = 'E-Detailing';
export const ROUTE_DCR = 'DCR';
export const ROUTE_DOCTOR_DETAILS = 'DOCTOR_DETAILS';

export const PARTY_TYPE = {
  DOCTOR: 'Doctor',
  CHEMIST: 'Chemist',
  ALL: 'All',
};

export const GENDER = {
  MALE: 'M',
  FEMALE: 'F',
  MALE_FULL: 'Male',
  FEMALE_FULL: 'Female',
};

export const ROLES = {
  SLM: 2,
  FLM: 3,
  MR: 4,
  TLM: 5,
};

export const API_PATH = {
  cannotAccess: '/contactdetail',
  main: {
    leftmenu: '/admin/leftmenu',
    userInfo: '/admin/user/me',
    notifications: 'notification/adminnotifications',
    markAsRead: '/notification/markasread',
    appSettings: '/user/appsettings',
    webAccessStatus: 'user/webAccess',
  },
  approveExpense: {
    dropdowns: {
      states: '/dropdown/states',
      divisions: '/dropdown/divisionsbyadminuser',
    },
    expenseAdmin: {
      managers: '/expenseadmin/expenseapproval/searchmanager/autosuggest',
      staff: '/expenseadmin/expenseapproval/searchstaff/autosuggest',
      expenseApprovals: '/expenseadmin/expenseapprovals',
      expenseApprovalsDaily: '/expenseadmin/expenseapprovals/expenses/daily',
      expenseApprovalsMisc: '/expenseadmin/expenseapprovals/expenses/misc',
    },
  },
  expenseDATAMaster: {
    addExpenseTAMaster: '/expenseadmin/expensetamaster/create',
    addExpenseDAMaster: '/expenseadmin/expensedamaster/create',
    addExpensevalidateDAMaster: '/expenseadmin/expensedamaster/create',
    addExpensevalidateTAMaster: '/expenseadmin/expensetamaster/create',
    editExpenseTAMaster: '/expenseadmin/expensetamaster/update',
    editExpenseDAMaster: '/expenseadmin/expensedamaster/update',
    expenseTAMasterGird: '/expenseadmin/expensetamasters',
    expenseDAMasterGird: '/expenseadmin/expensedamasters',
    expenseDATAMasterAutosuggest:
      '/expenseadmin/expensedatamaster/search/autosuggest',
    expenseTAMasterActiveInactive:
      '/expenseadmin/expensetamaster/active-inactive',
    expenseDAMasterActiveInactive:
      '/expenseadmin/expensedamaster/active-inactive',
    hqByDivisionsandDesignation:
      '/expenseadmin/gethqlistbydivisiondesignations',
    hqlistbydivisiondesignations:
      '/expenseadmin/gethqlistbydivisiondesignations',
    dropdowns: {
      designations: '/dropdown/designations',
      HQ: '/expenseadmin/gethqlistbydivisiondesignations',
      HQType: '/dropdown/categories/bytype',
    },
  },
  componentMaster: {
    expenseTypegrid: '/expenseadmin/expensetypes',
    expenseTypeActiveInactive: '/expenseadmin/expensetype/active-inactive',
    expenseTypeAutoSuggest: '/expenseadmin/expensetype/search/autosuggest',
    addExpenseType: '/expenseadmin/expensetype/create',
    editExpenseType: '/expenseadmin/expensetype/update',
    autoSuggestHq: '/expenseadmin/headquarterautosuggestlist',
    expenseTypeOption: '/dropdown/categories/bytype',
    expenseCategory: '/dropdown/categories/bytype',
  },
  generateReport: {
    getGenerateReport: '/expenseadmin/statusreport',
  },
  expenseReports: {
    getReportPages: '/expenseadmin/expenseadminreports',
    getReportData: '/expensereport/tasummary',
    getReportBifurcate: '/expensereport/bifurcationreport',
    getReportYearly: '/expensereport/yearlyreport',
  },
  statusManager: {
    autoSuggestStaff: '/expenseadmin/expenseapproval/searchstaff/autosuggest',
    getexpensestatusandactions: '/expenseadmin/getexpensestatusandactions',
    saveExpenseStatusAndActions: '/expenseadmin/saveexpensestatusaction',
  },
  billLibrary: {
    dropdown: {
      divisions: '/dropdown/divisionsbyadminuser',
      designationbydivision:
        '/expenseadmin/getdesignationbydivision?divisions=',
      employeebydivision: '/expenseadmin/getemployeebydivision?divisions=',
      hqByDivisionsandDesignation:
        '/expenseadmin/gethqbydivisionsanddesignations?divisions=',
      poolByDivisionsandDesignation:
        '/expenseadmin/getpoolbydivisionsanddesignations?divisions=',
      category: '/dropdown/categories/bytype',
    },
    downloadBill: '/expenseadmin/bills/download',
    viewHistory: '/expenseadmin/bills/downloadhistory/',
    reviewSave: '/expenseadmin/bills/savesearch',
    reviewSearch: '/expenseadmin/bills/employee/search',
  },
  expense: {
    parameter: '/expenseadmin/expenseadjustmentparameters',
    download:
      '/expenseadmin/downloadexpenseadjustmenttemplate?adjustmentParameterId=',
    upload: '/expenseadmin/uploadexpenseadjustment',
  },
  expensefilegenerate: {
    saveusexpensefilegenerate: '/expenseadmin/saveuserexpensenumber',
    downloadTemplate:
      '/expenseadmin/downloadexpensefilenotemplate?returnInBase64String=true',
  },
  configurationManagement: {
    expenseTypeSettingActiveInactive:
      '/expenseadmin/expensetypesettings/active-inactive',
    expenseTypeSettingGrid: '/expenseadmin/expensetypesettings',
    proRata: '/expenseadmin/expenseprorata',
    createExpenseTypeSetting: '/expenseadmin/expensetypesettings/create',
    createExpenseTypeSettingValidation:
      '/expenseadmin/expensetypesettings/create',
    editExpenseTypeSetting: '/expenseadmin/expensetypesettings/update',
    dropdown: {
      expenseTypeDropdown:
        '/expenseadmin/expensetypesettings/expensetypedropdown',
      hqByDivisionsandDesignation:
        '/expenseadmin/gethqlistbydivisiondesignations',
    },
    Expense_Date: '/expenseadmin/getexpensesubmissiondatewithalldivision',
    saveExpenseDate: '/expenseadmin/saveexpensesubmissiondate',
    proDataAdjustment: '/configuration-management/pro-data-adjustment',
    proRataEdit: '/expenseadmin/expenseprorata/create',
  },

  adminUsers: {
    user: '/admin/user',
    users: '/admin/users',
    editUser: '/admin/user/update',
    addUser: '/admin/user/create',
    addUserAutoSuggest: '/admin/users/autosuggest',
    disableUser: '/admin/user/active-inactive',
    autosuggest: '/admin/users/search/autosuggest',
    usersAutoSuggest: '/admin/users/autosuggest',
    dropdowns: {
      states: '/dropdown/states',
      divisions: '/dropdown/divisions',
      accessgroups: '/dropdown/accessgroups',
    },
  },
  accessGroups: {
    accessGroupsAutoSuggest: '/admin/accessgroup/search/autosuggest',
    fetchAccessGroups: '/admin/accessGroups',
    fetchAccessGroup: '/admin/accessGroup',
    editAccessGroup: '/admin/accessgroup/update',
    addAccessGroup: '/admin/accessgroup/create',
    disableAccessGroup: '/admin/accessgroup/active-inactive',
    menuItems: '/admin/menuitems',
  },
  planAndMeetStatus: {
    fetchPlanAndMeetList: '/planandmeet',
    updateMTPStatus: '/planandmeet/submitmtpbyadmin',
    editStpStatus: '/planandmeet/submitstpbyadmin',
    fetchPlanAndMeetListRecord: '/planandmeet/recordscount',
    mtpRecommendations: '/planandmeet/recommendations',
    filterSummaryCount: '/planandmeet/filterSummaryCount',
    hqsByRegion: '/planandmeet/hqsbyregion',
    defaultfilters: 'planandmeet/defaultfilters',
    resetFilters: 'planandmeet/ResetFilters',
    downloadExcel: '/planandmeet/download',
    preferredFilters: '/planandmeet/defaultfilters',
    mtpUnlockReasons: '/admincommon/mtpunlockreasons',
    unlockstpbyadmin: '/planandmeet/unlockstpbyadmin',
    unlockmtpbyadmin: '/planandmeet/unlockmtpbyadmin',
    unlockgspbyadmin: '/planandmeet/unlockgspbyadmin',
    fetchPlanAndMeetHierarchy: '/planandmeet/planandmeethierarchydata',
    fetchBulkHierarchy: '/planandmeet/bulkPlanAndMeetHierarchyData',
    downloadExcelHierarchyBased:
      '/planandmeet/downloadplanandmeethierarchydata',
    bulkGSPUpdate: '/planandmeet/bulkgspunlockbyadmin',
    webAccessUnlock: '/planandmeet/unlockwebaccess',
    bulkSTPUpdate: '/planandmeet/bulksubmitstpbyadmin',
    bulkMTPUpdate: '/planandmeet/bulksubmitmtpbyadmin',
    bulkDCRUpdate: '/planandmeet/bulkdcrstatuschangebyadmin',
    enableUnfilledDCR: '/planandmeet/unlockunfilleddcr',
    bulkStpUnlock: '/planandmeet/bulkunlockstpbyadmin',
    bulkMtpUnlock: '/planandmeet/bulkunlockmtpbyadmin',
    enableRealmUpload: 'planandmeet/realmdbconfig',
  },
  dcrUnlockRequest: {
    dcrUnlockRequestList: '/dcrunlockapproval',
    dcrUnlockRequestListRecords: '/dcrunlockapproval/recordscount',
    dcrUnlockReasons: '/dcrunlock/reason',
    approveDCRUnlockRequest: '/dcrunlockapproval/approvedcrunlockrequest',
    dcrHqsByRegion: '/dcrunlockapproval/dcrhqsfiltersummarycount',
    downloadExcel: '/dcrUnlockApproval/downloadExcel',
    modifyDCRRequest: '/dcrmodification/manageraction',
    bulkDCRUnlock: '/dcrunlockapproval/bulkapprovedcrunlockrequest',
  },
  positionManagement: {
    partyBySPID: '/party/partybyspid',
    areasBySPID: '/positioncloningtransfer/areasbyspid',
    positioncloningtransfer: '/positioncloningtransfer',
    cloningTimePeriod: '/positioncloningtransfer/cloningtransactionalduration',
    tranferParties: '/positionCloningTransfer/transferParties',
    positionCloneList: '/positioncloningtransfer/pendingrequests',
    positionTransferList: 'positioncloningtransfer/pendingtransferrequests',
    clonePositon: '/positionCloningTransfer/clone',
    validateTranferParties:
      '/positionCloningTransfer/validatetransferPartyareas',
    areaAllocation: '/positioncloningtransfer/areaallocation',
  },
  approvalWorkflow: {
    approvalWorkflowList: '/ApprovalWorkflow/gridData',
    masterConfigData: '/approvalworkflow/masterconfigurationdata',
    createApproval: '/approvalworkflow/create',
    updateMinApproverConfig: '/approvalworkflow/minapproverconfiguration',
  },
  inventoryModule: {
    confirmReturnItems: '/inventoryadmin/cnfreturnitems',
    intransistChallan: '/inventoryadmin/intransitchallans/master',
    autoSuggestionData: '/inventoryadmin/intransitchallanusersautosuggest',
    filteredData: '/inventoryadmin/intransitchallansearchfilterdata',
    postIntransitChallanOpenClose:
      '/inventoryadmin/postintransitchallanscloseopen',
    enableDisableIntransitChallans:
      '/inventoryadmin/enabledisableadminintransitchallans',
    voucherNumberData: '/inventoryadmin/intransitchallans/details',
    cnfStates: '/inventoryadmin/getadminuserdata',
    cnfDivisions: '/dropdown/divisions',
    cnfAuditTrail: '/inventoryadmin/cnfaudittrail/downloadcsv',
    cnfHeadquarters: '/inventoryadmin/gethqdata',
    cnfAutoSuggestionData: '/inventoryadmin/cnfreturnitemsautosuggest',
    cnfFilteredData: '/inventoryadmin/cnfreturnitemsfiltersearchdata',
    cnfReturnItemsFilter: '/inventoryadmin/cnfreturnitemsfilterdata',
    approvedItems: '/inventoryadmin/postcnfapprovereturnitems',
    rejectItems: '/inventoryadmin/postcnfrejectreturnitems',
    receivedChallans: '/inventoryadmin/intransitchallansisrecieved',
    pendencyReport: '/inventoryadmin/adminpendencyreportdata',
    divisionState: '/inventoryadmin/getadminstatesbydivision',
    itemSampleAutoSuggest:
      '/inventoryadmin/itemsamplewithdivisionmapautosuggest',
    challanReceiveSearchData:
      '/inventoryadmin/challanreceiveaudittrailfilterdata',
    challanAuditData: '/inventoryadmin/challanreceiveaudittrailloaddata',
    challanReportData: '/inventoryadmin/challanreceiveaudittrail/downloadcsv',
    challanAutoSuggest: '/inventoryadmin/challanreceiveaudittrailautosuggest',
    adminApprovalreturnItems: '/inventoryadmin/adminapprovalreturnitems',
    adminAutoSuggestionData:
      '/inventoryadmin/adminapprovalreturnitemsautosuggestwithdata',
    adminFilteredData:
      '/inventoryadmin/adminapprovalreturnitemsfilterwithsearchdata',
    adminApprovalCreate:
      '/inventoryadmin/createapprovalothersreturneditemsforadmin',
    adminApprovalReject:
      '/inventoryadmin/createrejectionothersreturneditemsforadmin',
    adminApprovalReturnFilterData:
      '/inventoryadmin/adminapprovalreturnitemsfilterdata',
    itemCategory: '/inventoryadmin/itemcategories',
    createitemcategoryqualificationmap:
      '/inventoryadmin/createitemcategoryqualificationmap',
    remmoveitemcategoryqualificationmap:
      '/inventoryadmin/remmoveitemcategoryqualificationmap',
    itemcategoryqualificationmaps:
      '/inventoryadmin/itemcategoryqualificationmaps',
    inventoryrules: '/inventoryadmin/inventoryrules',
    remmovinventoryrule: '/inventoryadmin/remmovinventoryrule',
    createinventoryrule: '/inventoryadmin/createinventoryrule',
    itemCategoryUser: '/inventoryadmin/itemcategoryqualificationmaps',
    inventoryRulesActiveInactive: '/inventoryadmin/inventoryrules',
    inventoryRulesActive: '/inventoryadmin/itemcategoryqualificationmapsactive',
    inventoryRulesInActive:
      '/inventoryadmin/itemcategoryqualificationmapsinactive',
    admingeointransitstatedivisionlockdata:
      '/inventoryadmin/admingeointransitstatedivisionlockdata',
    admingeointransituserlockdata:
      '/inventoryadmin/admingeointransituserlockdata',
    createadmindivisionintransitlock:
      '/inventoryadmin/createadmindivisionintransitlock',
    createadminstateintransitlock:
      '/inventoryadmin/createadminstateintransitlock',
    createadminuserintransitlock:
      '/inventoryadmin/createadminuserintransitlock',
    getadminuserstatedivisionroledata:
      '/inventoryadmin/getadminuserstatedivisionroledata',
    challanShortQtyApprovalListData:
      '/inventoryadmin/adminchallanshortqtyuserdetails',
    challanShortQtyApprove: '/inventoryadmin/adminchallanshortqty/approve',
    challanShortQtyReject: '/inventoryadmin/adminchallanshortqty/reject',
    autoSuggestChallan: '/inventoryadmin/challanshortquantityusersautosuggest',
    challanShortQtyAuditTrailDownload:
      '/inventoryadmin/challanshortquantityaudittrail/downloadcsv',
    autoSuggestForShortQtyAuditTrail:
      '/inventoryadmin/shortqtyaudittrailautosuggest',
    ChallanShortQtyLoadAuditTrailData:
      '/inventoryadmin/challanshortquantityeaudittrailloaddata',
    challanShortQtyFilterAuditTrailData:
      '/inventoryadmin/challanshortqtyauditfilterdata',
    subordinateDetails:
      '/inventoryadmin/admin/intransitchallan/subordinatedetails',
    userListData: '/inventoryadmin/userlistautosuggest',
    legderReportsAutoSuggestForStaff:
      '/inventoryadmin/ledgeruserlistautosuggest',
    pendencyExpectedDate:
      '/inventoryadmin/adminpendencyexpectedutilizationdate',
    itemAndSample: '/inventoryadmin/itemsamplewithdivisionmapdropdown',
    getItemOrSampleByUserId: '/inventoryadmin/itemsamplebyuserid',
    getLedgerReportData: '/inventoryadmin/inventoryledgerreport',
  },
  inventoryConfigurator: {
    inventoryDivision: '/dropdown/divisions',
    inventoryCustomerType: '/dropdown/customertype',
    inventoryRegionHeirarchy: '/dropdown/regionheirarchy',
    inventoryDoctorSpeciality: '/dropdown/doctorspeciality',
    inventoryDoctorQualification: '/dropdown/doctorqualification',
    inventorySpecCategory: '/dropdown/specialisationcategory',
    inventoryRoles: '/dropdown/roles',
    inventoryClassCode: '/dropdown/itemclasscode',
    inventoryItemCategory: '/dropdown/itemcategory',
    inventoryItemCode: '/dropdown/itemcode',
    inventorySave: '/inventory/configurator/define',
    inventoryQualification: '/dropdown/qualification',
  },
  expectedUtilizationDate: {
    getautosuggestsku: '/inventoryadmin/getautosuggestsku',
    getautosuggestitem: '/inventoryadmin/getautosuggestitem',
    expectutilizationDays: '/inventoryadmin/getexpectedutilizationdays',
    NewexpectutilizationDays: '/inventoryadmin/setexpectedutilizationdays',
  },
  activityMapping: {
    mappedActivityList: '/activitymapping/mapped',
    unmappedActiviytList: '/activitymapping/unmapped',
    saveActivityMapping: '/activitymapping/saveactivitymapping',
    activitiesList: '/activitymapping/getActivityConfigurations',
    divisionMapping: '/activitymapping/divisionMapping',
    saveActivities: '/activitymapping/SaveActivities',
  },

  moduleSpecificUtilities: {
    approveExpense: {
      rejectExpenseApprovals: '/expenseadmin/expenseapprovals/adminrejection',
      confirmExpenseApprovals: '/expenseadmin/expenseapprovals/adminapproval',
      dailyExpenseApprovals: '/expenseadmin/expenseapprovals/expenses/daily',
      exceptionSummary: '/expense/exceptionsummary',
      monthlyExpense: '/expensereport/monthlyexpense',
      miscExpenseApprovals: '/expenseadmin/expenseapprovals/expenses/misc',
      expenseApprovals: '/expenseadmin/expenseapprovals',
      managers: '/expenseadmin/expenseapproval/searchmanager/autosuggest',
      staffs: '/expenseadmin/expenseapproval/searchstaff/autosuggest',
      miscUploadFile: '/expense/bill/upload',
      viewBillDownload: '/expense/bill/download?blobName=',
      dailyExpenseDcrReport: '/expenseadmin/dcrreport',
      dailyExpenseDcrMapurl: '/expenseadmin/getstationscoveredurl?dcrId=',
      deleteMiscfile: '/expense/bill/delete',
      popupReadOnlyData: '/expense/',
      popuptadagetdata: '/expense/gettadaexpenselookupdetail?dcrId=',
      popuptadasavedata: '/expenseadmin/saveexpensedetailbyadmin',
      saveMisc: '/expenseadmin/savemiscdetailbyadmin',
      deductDaAmount: '/expenseadmin/SaveExpenseDADeduction',
      approveExpenseSataus: '/expense/expensesubmissiondetails/',
      footercalculation: '/expenseadmin/expenseapprovals/footercalculator',
      dropdowns: {
        states: '/dropdown/states',
        divisions: '/dropdown/divisionsbyadminuser',
        category: '/dropdown/categories/bytype',
        allStates: '/expenseadmin/expenseapproval/states',
        monthYear: '/expense/calendarmonths',
      },
      dailyExpenseViewBill: {
        viewBillList: '/expense/bills/list/',
        isMiscellaneousExpense: '?isMiscellaneousExpense=',
      },
      expenseBillValidate: '/expense/bill/validate',
      submitBill: '/expense/bill/submit',
      billDetails: '/expense/bills/list',
      supportingDocs: '/expense/bill/supportingbill/submit',
      deleteBill: '/expense/bills/delete',
      dailyAllExpenseApprovals: '/expensereport/dailyexpensereport',
      unlockDeduction: '/expenseadmin/calculatedcrunlockpenaltyamount',
    },
  },

  gspSchedule: {
    gspConfigurationList: 'gspconfiguration/list/',
    validatePrerequisites: 'gspconfiguration/validateprerequisitescondition/',
    saveconfiguration: 'gspconfiguration/saveconfiguration/',
    gspDivisions: 'gspconfiguration/divisionsperadmin',
  },
  dataCorrection: {
    dataCorrectionList: '/masterdataconfiguration/getmasterdatabytype',
    updateDataCorrection:
      '/masterdataconfiguration/updatemasterdataconfiguration',
  },
  holidayManagement: {
    getcountrylist: '/holiday/getcountrylist',
    getStateListByCountryID: '/holiday/getstatelistbycountryid',
    getHolidayByYear: '/holiday/getholidaybyyear?year=',
    getHolidayByHolidayID: '/holiday/getholidaybyholidayid',
    division: '/holiday/getdivisions',
    addHoliday: '/holiday/addholiday',
    updateHoliday: '/holiday/updateholiday',
    deleteHoliday: '/holiday/deleteholiday?HolidayId=',
    getAllowedHolidayCountListByYearCountry:
      '/holiday/getallowedholidaycountlistbyyearcountry',
    addAllowedHoliday: '/holiday/addallowedholiday',
    getAllowedHolidayById: '/holiday/getallowedholidaybyid',
    getStations: '/holiday/stations?SearchText=',
    getStaff: 'holiday/getstaffpositionbydivisionstationyear?StationId=',
    getSubordinates: 'leave/getsubordinates/',
    getBalanceLeaveByUserId: 'leave/getbalanceleavebyuserid?UserID=',
    getListRequestLeavesByUserId: 'leave/getlistrequestleavesbyuserid?userId=',
    applyLeaves: 'leave/applyleaves',
    deleteAllowedHoliday: '/holiday/deleteallowedholiday?AllowedHolidayId=',
    updateAllowedHoliday: '/holiday/updateallowedholiday',
    getStaffHolidays: '/holiday/getstaffholidays?StaffPositionId=',
    addStaffHoliday: '/holiday/addstaffholiday?userId=',
    deleteStaffHolidays: '/holiday/deletestaffholidays?UserId=',
    voidLeaves: '/leave/voidleaves',
    approvalLeaves: '/leave/ApprovalLeaves',
    allLeaveType: '/leave/types',
    checkDCR: 'leave/checkdcrfilled?FromDate=',
    checkHolidayFilled: 'holiday/checkifholidaysfilled',
    getAllowedHolidayCount: 'holiday/getallowedholidaycountbyuserid?year=',
    downloadLeaveExcelData: 'leave/downloadleavedata',
    userRoles: 'leave/roles',
    getAdminLeaveList: 'leave/getadminleavelist',
  },
  directoryConfiguration: {
    fetchTabs: '/admintab/search',
    fetchDivisions: '/admintab/divisionddl',
    fetchPartyGroups: '/admintab/partytypegroupddl',
    updateTab: '/admintab/save',
    fetchColumns: '/admincolumns/search',
    fetchTabList: '/admincolumns/tabddl',
    fetchColumnMappingFields: '/admincolumns/columndisplaynameddl',
    updateColumn: '/admincolumns/save',
    fetchTabColumns: '/admincolumnsmap/getdirectorycolumnslist',
    updateTabColumns: '/admincolumnsmap/activeinactivedirectoryconfigcolumns',
    hoContactCategory: '/admincontactcategory/search',
    updateHoContactCategory: '/admincontactcategory/save',
    hoUser: '/adminhocontact/contactcategoryddl',
    hoUserDepartment: '/adminhocontact/departmentddl',
    hoUserDivision: '/adminhocontact/admindivisionddl',
    hoUserState: '/adminhocontact/stateddl',
    hoUserRegion: '/adminhocontact/regionlist',
    hoUserList: '/adminhocontact/getallcontactdetail',
    hoUserSave: '/adminhocontact/savecontactdetail',
    hoUserUpdate: '/adminhocontact/updatecontactdetail',
    departmentList: '/admindepartment/search',
    departmentSave: '/admindepartment/save',
    socialMediaList: '/adminsocialmedia/list',
    getSocialMediaCategory: '/adminsocialmediacategory/ddl',
    saveSocialMediaCategory: '/adminsocialmedia/save',
    socialmediacategoryList: '/adminsocialmediacategory/list',
    socialMediaCategorySave: '/adminsocialmediacategory/save',
    getdirectoryconfigtabbytabid: '/admintab/getdirectoryconfigtabbytabid',
    getHoContactDetails: '/adminhocontact/getbyidcontactdetail',
  },
  onboardNewDivision: {
    divisionConfig: '/newdivisionconfiguration/newdivisionconfig',
    saveDivision: '/newdivisionconfiguration/savenewdivisionconfig',
  },
};

export const ROLES_TABLE = {
  1: 'Admin',
  2: 'SLM',
  3: 'FLM',
  4: 'MR',
  5: 'TLM',
  6: '4th Line',
  7: '5th Line',
  8: 'State Head',
  9: 'TopLM',
};

export const ROLES_RANK = {
  1: 99, // roleid: rank
  2: 3,
  3: 2,
  4: 1,
  5: 4,
  6: 5,
  7: 6,
  8: 7,
  9: 8,
};

export const PRESENTATION_SYNC = {
  PRESENTATION_SUBMIT_PENDING: 'presentationSubmitPending',
  PRESENTATION_DATA: 'presentationData',
  PRESENTATION_DATA_ANALYTICS: 'presentationDataAnalytics',
};
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const CALENDAR_DATE_FORMAT = 'DD-MM-YYYY';
export const NUMBER_DATE_FORMAT = 'DD/MM/YYYY';
export const MONTH_NAME_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_FORMAT = 'DD MMM YYYY, hh:mm A';
export const MONTH_DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_TIME_SECOND_FORMAT = 'YYYY-MM-DDThh:mm:ss';
export const YEAR_DATE_FORMAT = 'YYYY/MM/DD';
export const MONTH_DATE_FORMAT_1 = 'MM/DD/YYYY';

export const USER_APP_SETTINGS_KEY = {
  MTP_SUBMIT: 'MTPSUBMIT',
  MTP_GRACE_PERIOD: 'MTPGRACEPERIOD',
  FLM_MTP_SUBMISSION: 'FLMMTPSUBMISSION',
  MTP_COMP_PERCENT: 'MTPCOMPPERCENT',
  MTP_SUBMIT_WITHOUT_REMARK: 'MTPSUBMITWITHOUTREMARK',
  FLM_MTP_COMPLIANCE_PERCENT: 'FLMMTPCOMPLIANCEPERCENT',
  STP_COMP_PERCENT: 'STPCOMPPERCENT',
  STP_GRACE_PERIOD: 'STPGRACEPERIOD',
  SLM_MTP_COMPLIANCE_PERCENT: 'SLMMTPCOMPLIANCEPERCENT',
  API_TIMEOUT: 'APITIMEOUT',
  SYNC_INTERVAL: 'SYNCINTERVAL',
  MTPSUBMITLOCKED: 'MTPSUBMITLOCKED',
};

export const USER_APP_SETTINGS_TYPE = {
  START: 'start',
  END: 'end',
};

export const NOTIFICATION_REDIRECT = {
  cnfReturnApproval: '/inventory-management/Cnf-ReturnItems-Approval',
};

export const GEOLOCATION_TYPES = {
  COUNRY: 1,
  ZONE: 2,
  STATE: 3,
  SUBSTATE: 4,
  POOL: 5,
  STATION: 9,
  TERRITORY: 10,
};

export const PARTY_TYPE_ID = {
  DOCTOR: 1,
  CHEMIST: 2,
};

export const NOTIFICATION_TITLE = {
  TOUR_PLAN: {
    title: 'Tour Planning',
    actions: {
      MTP: 'MTP',
      doctorUniverse: 'Doctor Universe',
    },
  },
  E_DETAILING: {
    title: 'EDetailing content updated',
  },
};

export const NETWORK_REQUEST = {
  GET: 'GET',
  POST: 'POST',
};

export const OPEN_TASK_TYPE_NAME = {
  1: 'InfoRequest',
  2: 'SampleRequest',
  3: 'GiftRequest',
};

export const SHOW_GLOBAL_WARNING_CODE = 154;

export const DEVICE_TYPE = {
  WEB: 'Web',
  DEVICE: 'Device',
};
export const VALUE_EMPTY_PLACEHOLDER = '-';

export const USER_ROLE = 'USER_ROLE';
export const USER_STAFF_ID = 'USER_STAFF_ID';
export const USER_DIVISION = 'USER_DIVISION';
export const USER_NAME = 'USER_NAME';
export const USER_SAP_CODE = 'USER_SAP_CODE';
export const USER_ENVIRONMENT = 'USER_ENVIRONMENT';

export const STATIC = 'STATIC';

export const DCR_FEEDBACK = {
  SINGLE_VISIT: 'single',
  JOINT_VISIT: 'joint',
};

export const columnHeader = [
  {
    id: 0,
    requestor: 'NAME OF REQUESTOR',
    unlockDate: 'UNLOCK DATE',
    raisedOn: 'RAISED ON',
    unlockRequestType: 'UNLOCK REQUEST TYPE',
    reasonRequestor: 'REASON (FROM REQUESTOR)',
  },
];

export const DCR_STATUS = {
  PENDING: 1,
  SUBMITTED: 2,
};

export const DOCTOR_TYPES = ['Priority', 'Others'];
export const DOCTOR_TYPES_CONFIG = ['Priority'];
export const DOCTOR_TYPE = {
  PRIORITY: 'Priority',
  OTHERS: 'Others',
};

export const BRAND_TYPE = {
  MOTHER: 'MOTHER',
  SUB_BRAND: 'SUB_BRAND',
  SKU: 'SKU',
  DOCTOR: 'DR',
};

export const PLAN_LEVEL_TYPE = {
  VALUE: 'VALUE',
  UNITS: 'UNITS',
};

export const PENDING = 'Pending';
export const SUBMITTED = 'Submitted';

export const NO_RECORD = 'No Record';
export const WORK_IN_PROGRESS = 'Work In Progress';
export const WAITING_FOR_APPROVAL = 'Waiting For Approval';
export const APPROVED = 'Approved';
export const APPROVED_WITH_CHANGES = 'Approved With Changes';
export const PENDING_FLM_APPROVAL = 'Pending FLM Approval';
export const PENDING_SLM_APPROVAL = 'Pending SLM Approval';
export const PENDING_ADMIN_APPROVAL = 'Pending Admin Approval';
export const REJECTED = 'Rejected';

export const STP_STATUS = {
  1: PENDING,
  2: SUBMITTED,
  0: NO_RECORD,
};

export const STP_COLOR = {
  0: '#666', // black for no record
  1: '#f6b847', // yellow for pending
  2: '#35b53b', //green for submitted
};

export const DCR_PENDING = 'Pending';
export const DCR_APPROVED = 'Approved';
export const DCR_REJECTED = 'Rejected';
export const DCR_COMPLETED = 'Completed';
export const DCR_PENDING_FLM_APPROVAL = 'Pending FLM Approval';
export const DCR_PENDING_SLM_APPROVAL = 'Pending SLM Approval';
export const DCR_PENDING_ADMIN_APPROVAL = 'Pending Admin Approval';

export const DCR_UNLOCK_STATUS_VALUE = {
  1: DCR_PENDING,
  2: DCR_APPROVED,
  3: DCR_REJECTED,
  4: DCR_APPROVED,
  5: DCR_PENDING_FLM_APPROVAL,
  6: DCR_PENDING_SLM_APPROVAL,
  7: DCR_PENDING_ADMIN_APPROVAL,
};

export const UNLOCK_REQUEST_COLOR = {
  1: '#f6b847', // DCR Pending - BLACK
  2: '#35b53b', // DCR Approved - GREEN
  3: '#c74313', // DCR Rejected - RED
  4: '#35b53b', // DCR Completed - GREEN
  5: '#f6b847', // Pending FLM Approval - YELLOW
  6: '#f6b847', // Pending SLM Approval - YELLOW
  7: '#c74313', // Pending Admin Approval - RED
};

export const MTP_STATUS = {
  0: NO_RECORD,
  1: WORK_IN_PROGRESS,
  2: WAITING_FOR_APPROVAL,
  3: APPROVED,
  4: APPROVED_WITH_CHANGES,
  5: PENDING_FLM_APPROVAL,
  6: PENDING_SLM_APPROVAL,
  7: PENDING_ADMIN_APPROVAL,
  8: REJECTED,
};

export const MTP_STATUS_COLOR_CODES = {
  0: '#666666', // No Record - BLACK
  1: '#f6b847', // Work In Progress - YELLOW
  2: '#f6b847', // Waiting For Approval - YELLOW
  3: '#35b53b', // Approved - GREEN
  4: '#35b53b', // Approved With Changes - GREEN
  5: '#f6b847', // Pending FLM Approval - YELLOW
  6: '#f6b847', // Pending SLM Approval - YELLOW
  7: '#c74313', // Pending Admin Approval - RED
  8: '#793699', // Rejected - VIOLET
};

export const DCR_STATE = {
  ENABLE: 'Enabled',
  DISABLE: 'Disabled',
};

export const DCR_STATUS_LABEL = 'Review DCR';
export const STP_STATUS_LABEL = 'STP Status';
export const MTP_STATUS_LABEL = 'MTP Status';
export const LOCK_UNLOCK = 'Lock/Unlock';
export const HQ = 'Head Quarters';
export const REGIONS = 'Regions';
export const ZONES = 'Zones';
export const DIVISIONS = 'Divisions';
export const APPROVALSTATUSLABEL = 'Approval Status';
export const DCRTYPELABEL = 'DCR Type';

export const DCRSTATUSKEY = 'dcrStatus';
export const STPSTATUSKEY = 'stpStatus';
export const MTPSTATUSKEY = 'mtpStatus';
export const LOCKUNLOCKSTATUSKEY = 'lockUnlockStatus';
export const HEADQUARTERSKEY = 'headQuarters';
export const REGIONSKEY = 'regions';
export const ZONESKEY = 'zones';
export const DIVISIONSKEY = 'divisions';
export const APPROVALSTATUSKEY = 'approvalStatus';
export const DCRTYPEKEY = 'dcrType';

export const FILTERS_LABEL = {
  [DCRSTATUSKEY]: DCR_STATUS_LABEL,
  [STPSTATUSKEY]: STP_STATUS_LABEL,
  [MTPSTATUSKEY]: MTP_STATUS_LABEL,
  [LOCKUNLOCKSTATUSKEY]: LOCK_UNLOCK,
  [HEADQUARTERSKEY]: HQ,
  [REGIONSKEY]: REGIONS,
  [ZONESKEY]: ZONES,
  [DIVISIONSKEY]: DIVISIONS,
  [APPROVALSTATUSKEY]: APPROVALSTATUSLABEL,
  [DCRTYPEKEY]: DCRTYPELABEL,
};

export const HQ_LIMIT = 10;
export const STP_UNLOCK_TITLE = 'Unlock & Allow Submission for STP';
export const MTP_UNLOCK_TITLE = 'Unlock & Allow Submission for MTP';

export const STP_UNLOCK_DATE_ERROR =
  'STP unlock permitted only for 15 days from current date.';
export const MTP_UNLOCK_DATE_ERROR =
  'MTP unlock permitted only between current date and end of current month.';

export const POSITION_TYPE = {
  transfer: 'Transfer',
  clone: 'Clone',
};

export const CLONE_PENDING = 'Pending';
export const CLONE_COMPLETED = 'Completed';
export const CLONE_FAILED = 'Failed';

export const POSITION_CLONE_STATUS = {
  1: CLONE_PENDING,
  2: CLONE_COMPLETED,
  3: CLONE_FAILED,
};

export const CLONING_STATUS = {
  PENDING: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const POSITION_CLONE_STATUS_COLOR = {
  1: '#f6b847', // yellow for pending
  2: '#35b53b', // green for completed
  3: '#c74313', // red for failed
};

export const APPROVAL_TYPE = {
  admin: 'Admin(only)',
  approvalLevel: 'Approval Level',
};

export const BULK_GSP_STATUS = {
  failed: 1,
  success: 2,
};

export const WEB_ACCESS_STATUS = {
  failed: 1,
  success: 2,
};

export const Modified_DCR = 'Modified DCR';
export const Unfilled_DCR = 'Unfilled DCR';

export const DCR_TYPE = {
  1: Modified_DCR,
  2: Unfilled_DCR,
};

export const UNLOCK_REMARKS_TYPE = {
  1: 'Add',
  2: 'Edit',
  3: 'Delete',
  4: 'POB',
  5: 'NMNE',
  6: 'RCPA',
  7: 'Imported',
};

export const MODULE_SPECIFIC_UTILITIES = {
  APPROVE_EXPENSE: {
    dailyExpense: 'Daily Expense',
    miscellaneous: 'Miscellaneous',
    exceptionSummary: 'Exception Summary',
  },
};

export const STRINGS = {
  common: {
    question: '?',
  },
  moduleSpecificUtilities: {
    approveExpense: {
      screen1: {},
      screen2: {
        approveModal: {
          approveExpensesQuestion: 'Approve Expenses?',
          rejectExpensesQuestion: 'Reject Claim?',
        },
      },
    },
  },
};

export const GSP = 'gsp';
export const WEB = 'web';
export const STP = 'stp';
export const MTP = 'mtp';
export const REVIEW_DCR = 'reviewDcr';
export const ENABLE_DCR = 'enableDcr';
export const UNLOCK_STP = 'unlockSTP';
export const UNLOCK_MTP = 'unlockMTP';
export const DCR_UNLOCK = 'dcrUnlock';
export const ENABLE_REALM = 'enableRealm';

export const BULK_UPDATE_ACTIONS = {
  GSP,
  WEB,
  STP,
  MTP,
  REVIEW_DCR,
  ENABLE_DCR,
  UNLOCK_STP,
  UNLOCK_MTP,
  DCR_UNLOCK,
  ENABLE_REALM,
};

export const BULK_ACTIONS_CURRENT_MONTH = {
  GSP,
  WEB,
  STP,
  MTP,
  REVIEW_DCR,
  ENABLE_DCR,
  UNLOCK_STP,
};

export const BULK_ACTIONS_NEXT_MONTH = {
  MTP,
  UNLOCK_MTP,
};

export const BULK_UPDATE_ACTIONS_TITLE = {
  gsp: 'GSP Unlock',
  web: 'Web Access',
  stp: 'STP Status',
  mtp: 'MTP Status',
  reviewDcr: 'Review DCR',
  dcrUnlock: 'Unlock DCR',
  enableDcr: 'Enable DCR',
  unlockSTP: 'STP Unlock',
  unlockMTP: 'MTP Unlock',
  enableRealm: 'Enable Realm',
};

export const MANAGER_MTP_APPROVAL = 'MTP Approval for Managers';
export const MR_MTP_APPROVAL = 'MTP Approval for MR';

export const AREA_TRANSFER_TYPES = {
  unallocated: 'unallocated',
  allocated: 'allocated',
};

export const AREA_ACTIONS = {
  AREA_TRANSFER: 'AREA_TRANSFER',
  CHANGE_EFFECTIVE_FROM: 'CHANGE_EFFECTIVE_FROM',
  CHANGE_STATUS: 'CHANGE_STATUS',
};

export const ACTIVITY_MAPPING = {
  '0': 'Activity Type',
  '1': 'Activity Name',
};
export const DATE_FORMAT_LEAVE_HOLIDAY = 'DD-MMM-YYYY';
const currentYear = new Date().getFullYear() + 1;
const startYear = 2023;
const years = Array.from({length: currentYear - startYear + 1}, (_, index) => ({
  label: (startYear + index).toString(),
  id: (index + 1).toString(),
  value: startYear + index,
}));
export const holidayLeaveYearData = years;

export const JUMP_DROPDOWN_VALUES = ['UNITS', 'VALUE'];
export const PRODUCT_LEVEL_DROPDOWN_VALUES = ['MOTHER', 'SUB', 'SKU'];

export const seachFilterOptions = [
  // {
  //   name:'Position ID',
  //   value:1,
  //   key:'staffPositionId'
  // },
  {
    name: 'WSFA Position ID',
    value: 1,
    key: 'wsfaPositionCode',
  },
  {
    name: 'Employee ID',
    value: 2,
    key: 'employeeId',
  },
  {
    name: 'Employee Name',
    value: 3,
    key: 'employeeName',
  },
];

export const DataCorrectionOption = [
  {
    name: 'Party Map Date',
    id: 1,
    key: 'partyMapDate',
  },
];

export const START_DATE_ERROR = 'Incorrect date';

export const ALL = 'All';
export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';

export const STATUS_TYPES = {
  0: ALL,
  1: ACTIVE,
  2: INACTIVE,
};

export const YES_NO = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
];
const monthObjects = [];
const currentMonth = new Date().getMonth(); // Gets the current month (0-based index)
for (let i = 0; i <= currentMonth; i++) {
  const date = new Date(currentYear, i);
  const monthName = date.toLocaleString('default', {month: 'long'});

  monthObjects.push({
    id: i + 1,
    value: monthName,
  });
}
export const JAN_TO_TILL_MONTH = monthObjects;

export const LEAVE_REPORT_TYPES = [
  {
    id: 1,
    value: 'Leave Register',
  },
  // {
  //   id: 2,
  //   value: 'Leave Bifurcation',
  // },
  {
    id: 3,
    value: 'Leave Detail',
  },
  {
    id: 4,
    value: 'Leave Summary',
  },
];
export const TOAST_MESSAGES = {
  SUCCESSFULLY_ADD: 'Record has been successfully added!',
  UNSUCCESSFULL_ADD: 'Record Not Added!',
  SUCCESSFULLY_UPDATED: 'Record has been updated!',
  UNSUCCESSFULL_UPDATE: 'Record Not Updated!',
};
