import {createSelector} from '@reduxjs/toolkit';

export const approveExpenseStateSelector = {
  getFilterData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.approveExpesnseFilterValues],
      approveExpesnseFilterValues => approveExpesnseFilterValues,
    );
  },
  getDivisionData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.divisionData],
      divisionData => divisionData,
    );
  },
  getStateData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.stateData],
      stateData => stateData,
    );
  },
  getMiscExpense: () => {
    return createSelector(
      [(state: any) => state.approveExpense.miscExpense],
      miscExpense => miscExpense,
    );
  },

  getManagerData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.managerData],
      managerData => managerData,
    );
  },
  getManagerLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.managersLoading],
      managersLoading => managersLoading,
    );
  },
  getSelectedManagerData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.selectedManager],
      selectedManager => selectedManager,
    );
  },
  getStaffData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.staffData],
      staffData => staffData,
    );
  },
  getStaffLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.staffLoading],
      staffLoading => staffLoading,
    );
  },
  getSelectedStaffData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.selectedStaff],
      selectedStaff => selectedStaff,
    );
  },
  getMonthData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.monthsData],
      monthsData => monthsData,
    );
  },
  getYearData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.yearsData],
      yearsData => yearsData,
    );
  },
  getStatusData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.statusData],
      statusData => statusData,
    );
  },
  getExpenseApprovalsData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.expenseApprovalsData],
      expenseApprovalsData => expenseApprovalsData,
    );
  },
  getExpenseApprovalsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.expenseApprovalsLoading],
      expenseApprovalsLoading => expenseApprovalsLoading,
    );
  },
  getSelectedExpenseApprovalsData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.selectedExpenseApprovalsData],
      selectedExpenseApprovalsData => selectedExpenseApprovalsData,
    );
  },
  getFlattenedExpenseApprovalsData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.flattenedExpenseApprovalsData],
      flattenedExpenseApprovalsData => flattenedExpenseApprovalsData,
    );
  },
  getIsExpenseAprrovalsAllSelected: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isExpenseAprrovalsAllSelected],
      isExpenseAprrovalsAllSelected => isExpenseAprrovalsAllSelected,
    );
  },
  getApproveExpenseScreen: () => {
    return createSelector(
      [(state: any) => state.approveExpense.approveExpenseScreen],
      approveExpenseScreen => approveExpenseScreen,
    );
  },
  getExceptionSummaryData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyExpenseExceptionSummaryData],
      dailyExpenseExceptionSummaryData => dailyExpenseExceptionSummaryData,
    );
  },
  getSelectedExpenseApprovalsUser: () => {
    return createSelector(
      [(state: any) => state.approveExpense.selectedExpenseApprovalsUser],
      selectedExpenseApprovalsUser => selectedExpenseApprovalsUser,
    );
  },
  getDailyExpenseApprovalsData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyExpenseApprovalsData],
      dailyExpenseApprovalsData => dailyExpenseApprovalsData,
    );
  },
  getDailyExpenseApprovalsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyExpenseApprovalsLoading],
      dailyExpenseApprovalsLoading => dailyExpenseApprovalsLoading,
    );
  },
  getDailyCountData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailys],
      dailys => dailys,
    );
  },
  getMiscCountData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.miscs],
      miscs => miscs,
    );
  },
  getMiscExpenseApprovalsData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.miscExpenseApprovalsData],
      miscExpenseApprovalsData => miscExpenseApprovalsData,
    );
  },
  getMiscExpenseApprovalsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.miscExpenseApprovalsLoading],
      miscExpenseApprovalsLoading => miscExpenseApprovalsLoading,
    );
  },
  getIsAprroveExpenseModalOpen: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isAprroveExpenseModalOpen],
      isAprroveExpenseModalOpen => isAprroveExpenseModalOpen,
    );
  },
  getApproveExpenseModalCheckbox: () => {
    return createSelector(
      [(state: any) => state.approveExpense.approveExpenseModalCheckbox],
      approveExpenseModalCheckbox => approveExpenseModalCheckbox,
    );
  },
  getConfirmExpenseApprovalsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.confirmExpenseApprovalsLoading],
      confirmExpenseApprovalsLoading => confirmExpenseApprovalsLoading,
    );
  },
  getConfirmExpenseApprovalsSucess: () => {
    return createSelector(
      [(state: any) => state.approveExpense.confirmExpenseApprovalsSucess],
      confirmExpenseApprovalsSucess => confirmExpenseApprovalsSucess,
    );
  },
  getConfirmExpenseApprovalsError: () => {
    return createSelector(
      [(state: any) => state.approveExpense.confirmExpenseApprovalsError],
      confirmExpenseApprovalsError => confirmExpenseApprovalsError,
    );
  },
  getIsRejectExpenseModalOpen: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isRejectExpenseModalOpen],
      isRejectExpenseModalOpen => isRejectExpenseModalOpen,
    );
  },
  getRejectExpenseModalCheckbox: () => {
    return createSelector(
      [(state: any) => state.approveExpense.rejectExpenseModalCheckbox],
      rejectExpenseModalCheckbox => rejectExpenseModalCheckbox,
    );
  },
  getRejectExpenseApprovalsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.rejectExpenseApprovalsLoading],
      rejectExpenseApprovalsLoading => rejectExpenseApprovalsLoading,
    );
  },
  getRejectExpenseApprovalsSucess: () => {
    return createSelector(
      [(state: any) => state.approveExpense.rejectExpenseApprovalsSucess],
      rejectExpenseApprovalsSucess => rejectExpenseApprovalsSucess,
    );
  },
  getRejectExpenseApprovalsError: () => {
    return createSelector(
      [(state: any) => state.approveExpense.rejectExpenseApprovalsError],
      rejectExpenseApprovalsError => rejectExpenseApprovalsError,
    );
  },

  setDcrReportPopupVisible: () => {
    return createSelector(
      [(state: any) => state.approveExpense.setDcrReportPopupVisible],
      setDcrReportPopupVisible => setDcrReportPopupVisible,
    );
  },
  setDeductTheAmountPopup: () => {
    return createSelector(
      [(state: any) => state.approveExpense.setDeductTheAmountPopup],
      setDeductTheAmountPopup => setDeductTheAmountPopup,
    );
  },
  getdaAmountToDeduct: () => {
    return createSelector(
      [(state: any) => state.approveExpense.daAmountToDeduct],
      daAmountToDeduct => daAmountToDeduct,
    );
  },
  getViewBillPopupVisible: () => {
    return createSelector(
      [(state: any) => state.approveExpense.setViewBillPopupVisible],
      setViewBillPopupVisible => setViewBillPopupVisible,
    );
  },
  getMiscExpenseApprovalsRequest: () => {
    return createSelector(
      [(state: any) => state.approveExpense.miscExpenseApprovalsRequest],
      miscExpenseApprovalsRequest => miscExpenseApprovalsRequest,
    );
  },
  getexpenseApprovalsViewBillData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.expenseApprovalsViewBillData],
      expenseApprovalsViewBillData => expenseApprovalsViewBillData,
    );
  },
  getexpenseApprovalsViewBillLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.expenseApprovalsViewBillLoading],
      expenseApprovalsViewBillLoading => expenseApprovalsViewBillLoading,
    );
  },

  getmiscexpenseApprovalsViewBillData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.expenseApprovalsViewBillData],
      expenseApprovalsViewBillData => expenseApprovalsViewBillData,
    );
  },
  getmiscexpenseApprovalsViewBillLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.expenseApprovalsViewBillLoading],
      expenseApprovalsViewBillLoading => expenseApprovalsViewBillLoading,
    );
  },

  getClickableLinkDataData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.getClickableLinkDataData],
      getClickableLinkDataData => getClickableLinkDataData,
    );
  },
  getClickableLinkDataLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.getClickableLinkDataLoading],
      getClickableLinkDataLoading => getClickableLinkDataLoading,
    );
  },
  setErrorPopupVisible: () => {
    return createSelector(
      [(state: any) => state.approveExpense.setErrorPopupVisible],
      setErrorPopupVisible => setErrorPopupVisible,
    );
  },
  getDailyExpenseDcrReportData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyExpenseDcrReportData],
      dailyExpenseDcrReportData => dailyExpenseDcrReportData,
    );
  },
  getDailyExpenseDcrReportLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyExpenseDcrReportLoading],
      dailyExpenseDcrReportLoading => dailyExpenseDcrReportLoading,
    );
  },
  getDailyExpenseDcrMapUrl: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyExpenseDcrMapUrl],
      dailyExpenseDcrMapUrl => dailyExpenseDcrMapUrl,
    );
  },
  setDailyExpenseHQTypeAdmin: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyExpenseHQTypeAdmin],
      dailyExpenseHQTypeAdmin => dailyExpenseHQTypeAdmin,
    );
  },

  setDailyEditedRowColorIndex: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyEditedRowColorIndex],
      dailyEditedRowColorIndex => dailyEditedRowColorIndex,
    );
  },
  getMiscEditedRowColorIndex: () => {
    return createSelector(
      [(state: any) => state.approveExpense.miscEditedRowColorIndex],
      miscEditedRowColorIndex => miscEditedRowColorIndex,
    );
  },
  getTaDaPopupVisible: () => {
    return createSelector(
      [(state: any) => state.approveExpense.taDaPopupVisible],
      taDaPopupVisible => taDaPopupVisible,
    );
  },
  getTaGridValue: () => {
    return createSelector(
      [(state: any) => state.approveExpense.taGridValue],
      taGridValue => taGridValue,
    );
  },
  getDaGridValue: () => {
    return createSelector(
      [(state: any) => state.approveExpense.daGridValue],
      daGridValue => daGridValue,
    );
  },
  getPopupReadOnlyData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.popupReadOnlyData],
      popupReadOnlyData => popupReadOnlyData,
    );
  },

  getPopuptadaData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.popuptadaData],
      popuptadaData => popuptadaData,
    );
  },
  getTaGrid: () => {
    return createSelector(
      [(state: any) => state.approveExpense.taGrid],
      taGrid => taGrid,
    );
  },
  getDaGrid: () => {
    return createSelector(
      [(state: any) => state.approveExpense.daGrid],
      daGrid => daGrid,
    );
  },
  getTaRate: () => {
    return createSelector(
      [(state: any) => state.approveExpense.taRate],
      taRate => taRate,
    );
  },
  getBillPathArr: () => {
    return createSelector(
      [(state: any) => state.approveExpense.billPathArr],
      billPathArr => billPathArr,
    );
  },
  getPageNo: () => {
    return createSelector(
      [(state: any) => state.approveExpense.pageNo],
      pageNo => pageNo,
    );
  },
  getDisableSave: () => {
    return createSelector(
      [(state: any) => state.approveExpense.disableSave],
      disableSave => disableSave,
    );
  },
  getApproveExpensestatus: () => {
    return createSelector(
      [(state: any) => state.approveExpense.approveExpensestatus],
      approveExpensestatus => approveExpensestatus,
    );
  },
  getDisableDaSave: () => {
    return createSelector(
      [(state: any) => state.approveExpense.disableDaSave],
      disableDaSave => disableDaSave,
    );
  },
  getDailyEditedRowColorIndexArr1: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dailyEditedRowColorIndexArr1],
      dailyEditedRowColorIndexArr1 => dailyEditedRowColorIndexArr1,
    );
  },
  getDisableaApproveExpense: () => {
    return createSelector(
      [(state: any) => state.approveExpense.disableaApproveExpense],
      disableaApproveExpense => disableaApproveExpense,
    );
  },
  getApprovedStaffDetails: () => {
    return createSelector(
      [(state: any) => state.approveExpense.approvedStaffDetails],
      approvedStaffDetails => approvedStaffDetails,
    );
  },
  getDeductAmount: () => {
    return createSelector(
      [(state: any) => state.approveExpense.deductAmount],
      deductAmount => deductAmount,
    );
  },
  getTravelDetailPopup: () => {
    return createSelector(
      [(state: any) => state.approveExpense.travelDetailPopup],
      travelDetailPopup => travelDetailPopup,
    );
  },
  getTravelDetails: () => {
    return createSelector(
      [(state: any) => state.approveExpense.travelDetails],
      travelDetails => travelDetails,
    );
  },
  getMonthYearData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.monthYearData],
      monthYearData => monthYearData,
    );
  },
  getMonthlyExpense: () => {
    return createSelector(
      [(state: any) => state.approveExpense.monthlyExpense],
      monthlyExpense => monthlyExpense,
    );
  },
  getFooterDatas: () => {
    return createSelector(
      [(state: any) => state.approveExpense.footerDatas],
      footerDatas => footerDatas,
    );
  },
  getDownloadAllMonthlyData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.downloadAllData],
      downloadAllData => downloadAllData,
    );
  },
  getInvoiceStatusofBill: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isInvoiceExist],
      isInvoiceExist => isInvoiceExist,
    );
  },
  getSubmittedBillStatus: () => {
    return createSelector(
      [(state: any) => state.approveExpense.IsBillSubmitted],
      isBillSubmitted => isBillSubmitted,
    );
  },
  getBillDetails: () => {
    return createSelector(
      [(state: any) => state.approveExpense.billDetails],
      billDetails => billDetails,
    );
  },
  getIsBillDeleted: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isBillDeleted],
      isBillDeleted => isBillDeleted,
    );
  },
  getIsSupportingDocSubmitted: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isSupportingDocSubmitted],
      isSupportingDocSubmitted => isSupportingDocSubmitted,
    );
  },
  getDailyAllData: () => {
    return createSelector(
      [(state: any) => state.approveExpense.dialyAllData],
      dialyAllData => dialyAllData,
    );
  },
  getSingleUserDailyExpense: () => {
    return createSelector(
      [(state: any) => state.approveExpense.singleUserDailyExpense],
      singleUserDailyExpense => singleUserDailyExpense,
    );
  },
  getTableMessage: () => {
    return createSelector(
      [(state: any) => state.approveExpense.tableMessage],
      tableMessage => tableMessage,
    );
  },
  getBillIsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.billIsLoading],
      billIsLoading => billIsLoading,
    );
  },
  getIsInvoiceVaildationIsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isInvoiceVaildationIsLoading],
      isInvoiceVaildationIsLoading => isInvoiceVaildationIsLoading,
    );
  },
  getUnlockDeduction: () => {
    return createSelector(
      [(state: any) => state.approveExpense.unlockDeduction],
      unlockDeduction => unlockDeduction,
    );
  },
  getUnlockDeductionLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isUnlockDeductionLoading],
      isUnlockDeductionLoading => isUnlockDeductionLoading,
    );
  },
  getFooterLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isFooterLoading],
      isFooterLoading => isFooterLoading,
    );
  },
  getisSaveExpenseDetailsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isSaveExpenseDetailsLoading],
      isSaveExpenseDetailsLoading => isSaveExpenseDetailsLoading,
    );
  },
  getisSaveMiscDetailsLoading: () => {
    return createSelector(
      [(state: any) => state.approveExpense.isSaveMiscDetailsLoading],
      isSaveMiscDetailsLoading => isSaveMiscDetailsLoading,
    );
  },
  getUploadBillPopupVisible: () => {
    return createSelector(
      [(state: any) => state.approveExpense.uploadBillPopup],
      uploadBillPopup => uploadBillPopup,
    );
  },
};
