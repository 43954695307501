import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Excel from 'exceljs';
import {saveAs} from 'file-saver';
import {SEARCH_OBJECT} from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import {MenuProp, useStyles} from '@app/screens/Styles/style';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {expenseReportsActions} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/index';
import {CustomMenuItem} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseHeader';
import React, {useEffect, useState} from 'react';
import {
  approveExpenseStateActions,
  fetchDivisionDataCreator,
  fetchStaffDataCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {fetchExpenseReportYearlyCreator} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/slice';
import {
  SearchComponent,
  SelectStaff,
  getPreviousYearMonths,
  selectedStaffAndEmployeeCode,
} from '@app/screens/module-specific-utilities/components/expense-reports/TA-summary/TASummaryReportHeader';
import {
  monthNames,
  getTotal,
  getMonthlyData,
} from '@app/screens/module-specific-utilities/components/expense-reports/yearly-disbursement-summary/yearlySummaryReport';
import {getHeaderStyles} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseTable';
import moment from 'moment';
import {parentHeader} from '@app/screens/module-specific-utilities/components/expense-reports/bifurcationReport/ExpenseBifurcationReport';
import {isEqual} from 'lodash';
import {withPreventDoubleClick} from '@app/components/hoc';
import {strings} from '@app/common/strings';

export const utilYe: any = {
  editStaffHandler: null,
  handleChangeDivisionId: null,
  handleChangeMonthYear: null,
  onStaffChange: null,
  downloadExcel: null,
  handleOnClickGo: null,
  generatePDF: null,
};
export const style = {
  change: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 200,
  },
};
export const fitToColumnExcelJsAoA = (
  worksheet: any,
  data: any[][],
  header: string[],
  divisor?: any,
  currentRowIdx?: any,
) => {
  if (!data || data.length === 0 || !data[0]) {
    return;
  }

  const columnCount = header.length;

  for (let columnIndex = 1; columnIndex <= columnCount; columnIndex++) {
    const maxColumnWidth = Math.max(
      header[columnIndex - 1].length * 10,
      ...data.map(row =>
        row[columnIndex - 1] ? row[columnIndex - 1].toString().length * 10 : 0,
      ),
    );
    if (parentHeader.includes(header[columnIndex])) {
      worksheet.getRow(currentRowIdx).getCell(columnIndex + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFFF00'},
      };
      worksheet.getColumn(header.length).font = {bold: true};
    }

    worksheet.getColumn(columnIndex).width = maxColumnWidth / divisor;
  }
};

const generateTableRows = (data: any, tableRowKeys: any, totalRow: any) => {
  return data
    .map((tableRow: any, index: any) => {
      const rowArray = [
        index + 1,
        ...tableRowKeys.map((row: any) => {
          if (row === 'dateOfJoining') {
            return moment(tableRow[row])?.format('D-MMM-YY');
          } else if (row === 'staffName') {
            return `${tableRow['staffFirstName']} ${
              tableRow['staffMiddleName'] || ''
            } ${tableRow['staffLastName']}`;
          } else if (row.startsWith('month')) {
            const month = parseInt(row.slice(5));
            const monthData = tableRow.expenseMonthItems.find(
              (item: any) => item.expenseMonth === month,
            );
            return getMonthlyData(monthData);
          } else if (row === 'TOTAL') {
            return getTotal(tableRow.expenseMonthItems);
          } else {
            return tableRow[row];
          }
        }),
      ];
      return rowArray;
    })
    .concat([totalRow]);
};

const Name = (id: any, arr: any, disName: any, idName: any) => {
  return arr
    .map((val: any) => {
      if (val?.['' + idName + ''] == id) {
        return val?.['' + disName + ''];
      }
    })
    .filter((element: any) => Boolean(element));
};

const YearlySummaryHeader: React.FC<{setReport: any}> = ({setReport}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [validated, setValidated] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedStaff, setSelectedStaff] = useState<any[]>([]);
  const filteredData = useSelector(
    expenseReportsStateSelector.getFiltersTASummary(),
  );
  const divisionData = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const selectedStaffData = useSelector(
    expenseReportsStateSelector?.getSelectedStaffData(),
  );

  const monthYearData = getPreviousYearMonths();

  const staffData: SEARCH_OBJECT[] = useSelector(
    approveExpenseStateSelector?.getStaffData(),
  );

  const reportData: any = useSelector(
    expenseReportsStateSelector.getYearlyReport(),
  );
  const isActive = useSelector(expenseReportsStateSelector.getIsActiveYearly());
  const DebouncedButton = withPreventDoubleClick(Button, 400);
  const uniqueMonths: any[] = [
    ...new Set(
      reportData?.reduce((months: any, item: any) => {
        return months.concat(
          item.expenseMonthItems.map(
            (monthItem: any) => monthItem.expenseMonth,
          ),
        );
      }, []),
    ),
  ];

  const monthAbbreviations: any[] = [
    ...new Set(
      reportData?.reduce((months: any, item: any) => {
        return months.concat(
          item.expenseMonthItems.map(
            (monthItem: any) =>
              monthNames[monthItem.expenseMonth - 1] +
              '_' +
              monthItem.expenseYear,
          ),
        );
      }, []),
    ),
  ];
  const getTotalAll = (type: string) => {
    if (type === 'AVERAGE') {
      return reportData?.reduce(
        (acc: number, curr: any) => acc + curr.averageAmount,
        0,
      );
    }
    if (type === 'MONTHS') {
      return reportData?.reduce(
        (acc: number, curr: any) => acc + getTotal(curr.expenseMonthItems),
        0,
      );
    }
  };

  const getTotalMonth = (month: any) => {
    return reportData.reduce((total: number, employee: any) => {
      const monthData = employee.expenseMonthItems.find(
        (item: any) => item.expenseMonth === month,
      );
      return total + (monthData ? monthData.adminApprovedTotalAmount : 0);
    }, 0);
  };

  let header = [
    'S. No.',
    'Staff Code',
    'Staff Name',
    'Division',
    'Designation',
    'DOJ',
    'Employee Code',
    'HQ',
    'TOTAL',
    'Average',
  ];
  header = [...header.slice(0, 8), ...monthAbbreviations, ...header.slice(8)];

  const tableRowKeys = [
    'staffCode',
    'staffName',
    'division',
    'designationName',
    'dateOfJoining',
    'sapCode',
    'hqName',
    ...uniqueMonths.map(month => `month${month}`),
    'TOTAL',
    'averageAmount',
  ];

  let totalRow = [
    'Total',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ...uniqueMonths.map(month => getTotalMonth(month)),
    getTotalAll('MONTHS'),
    getTotalAll('AVERAGE'),
  ];

  const arrayOfArraysData = generateTableRows(
    reportData,
    tableRowKeys,
    totalRow,
  );

  const debouncedStaffSearch = (criteria: string | any[]) => {
    if (filteredData?.divisionId && criteria?.length > 0) {
      dispatch(
        fetchStaffDataCreator({
          searchData: criteria,
          divisionIds: filteredData?.divisionId,
          InActive: !isActive,
        }),
      );
    }
  };

  const editStaffHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === undefined || e === null) return;
    if (e?.target?.value?.length >= 3) debouncedStaffSearch(e?.target?.value);
  };

  const handleChangeDivisionId = (value: number[], type: string) => {
    const {filteredSelectedStaff, selectedEmployeeCode} =
      selectedStaffAndEmployeeCode(selectedStaff, value, 'staffPositionId');
    if (value.length <= 10) {
      dispatch(
        expenseReportsActions.setFiltersTA({
          [type]: value,
        }),
      );
      dispatch(approveExpenseStateActions?.setStaffData([]));
      if (!isEqual(selectedStaff, filteredSelectedStaff)) {
        setSelectedStaff(filteredSelectedStaff);
        dispatch(
          expenseReportsActions?.setSelectedStaffData(selectedEmployeeCode),
        );
      }
    }
    dispatch(expenseReportsActions?.setYearlyReport([]));
  };

  const handleChangeMonthYear = function (value: any[], type: string) {
    value?.forEach((ele: number) => {
      if (
        ele === 0 &&
        filteredData?.monthYearData?.length === monthYearData.length
      ) {
        dispatch(
          expenseReportsActions?.setFiltersTA({
            [type]: [],
          }),
        );
      } else if (ele === 0) {
        dispatch(
          expenseReportsActions?.setFiltersTA({
            [type]: monthYearData.map((obj: any) => Object.values(obj)[2]),
          }),
        );
      } else {
        dispatch(
          expenseReportsActions?.setFiltersTA({
            [type]: value,
          }),
        );
      }
    });
    if (value.length <= 0) {
      dispatch(
        expenseReportsActions.setFiltersTA({
          [type]: value,
        }),
      );
    }
  };

  const prevSelectedStaffv = (prevSelectedStaff: any, event: any) =>
    prevSelectedStaff.filter((staff: {staffPositionId: any}) =>
      event.target.value.includes(staff.staffPositionId),
    );

  const condit = (updatedSelectedStaff: any, staff: any) =>
    !updatedSelectedStaff.some(
      (s: {staffPositionId: number}) =>
        s.staffPositionId === staff.staffPositionId,
    );
  const onStaffChange = (event: any) => {
    setSelectedStaff(prevSelectedStaff => {
      // Remove deselected staff codes
      const updatedSelectedStaff = prevSelectedStaffv(prevSelectedStaff, event);

      // Add newly selected staff codes
      staffData.forEach(staff => {
        if (
          event.target.value.includes(staff.staffPositionId) &&
          condit(updatedSelectedStaff, staff)
        ) {
          updatedSelectedStaff.push(staff);
        }
      });

      return updatedSelectedStaff;
    });
    dispatch(expenseReportsActions?.setSelectedStaffData(event.target.value));
  };
  const generatePDF = () => {
    const docs = new jsPDF('l', 'mm', 'a4');
    const options = {
      margin: {top: 48, left: 10},
      didDrawPage: () => {
        docs.text('YEARLY DISBURSEMENT SUMMARY', 100, 12);
        docs.setFontSize(11);
      },
      createdRow: (row: any) => {
        row.styles.lineWidth = 0.5;
      },
      drawCell: () => {
        docs.setFontSize(4);
      },
      startY: 50,
    };
    (docs as any).autoTable({
      head: [header],
      body: arrayOfArraysData,
      theme: 'grid',
      headStyles: getHeaderStyles(7),
      styles: {
        fontSize: 5,
        halign: 'center',
        textColor: [1, 1, 1, 1],
      },
      willDrawCell: (row: any, data: any, body: any) => {
        if (row.row.raw[0] === 'Total') {
          docs.setTextColor(0, 0, 0);
          docs.setFontSize(7.5);
        }
      },
      ...options,
    });

    const pgCount = (docs as any).internal.getNumberOfPages();
    const date = new Date();
    const docMinutes = date.getMinutes();
    const pdfDateString = `${date.toLocaleDateString()} ${date.getHours()}:${
      docMinutes < 10 ? `0${docMinutes}` : docMinutes
    }`;
    for (let i = 1; i <= pgCount; i++) {
      docs.setPage(i);
      docs.setFontSize(8);
      docs.text(pdfDateString, 14, docs.internal.pageSize.height - 10);
      docs.text(
        `Page ${i} of ${pgCount}`,
        264,
        docs.internal.pageSize.height - 10,
      );
    }
    docs.save('Yearly Disbursement Summary.pdf');
  };

  const downloadExcel = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(
      strings.ModuleSpecificUtilitie.expenseReportPage
        .Yearly_Disbursement_Summary,
    );
    worksheet.addRow(header);
    arrayOfArraysData.forEach((rowData: any) => worksheet.addRow(rowData));
    let currentRowIdx = worksheet.rowCount;
    worksheet.getRow(currentRowIdx).font = {bold: true};
    worksheet.getRow(1).font = {bold: true};
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'C0C0C0'},
    };
    fitToColumnExcelJsAoA(
      worksheet,
      arrayOfArraysData,
      header,
      7,
      currentRowIdx,
    );
    workbook.xlsx
      .writeBuffer()
      .then(buffer => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileName = `yearlyDisbursementSummary.xlsx`;
        saveAs(blob, fileName, {autoBom: false});
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleOnClickGo = () => {
    dispatch(
      fetchExpenseReportYearlyCreator({
        months: monthYearData
          .filter(monthYear =>
            filteredData.monthYearData.includes(monthYear.month),
          )
          .map((monthYear: any) => ({
            month: monthYear.month,
            year: monthYear.year,
          })),
        staffPositionIds: selectedStaffData?.filter((element: any) =>
          Boolean(element),
        ),
        divIds: filteredData.divisionId,
        InActive: !isActive,
      }),
    );
    setReport(true);
    setOpen(true);
  };

  const validation = () => {
    if (
      filteredData?.divisionId?.length !== 0 &&
      filteredData?.monthYearData?.length !== 0 &&
      filteredData?.monthYearData !== undefined
    ) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  useEffect(() => {
    validation();
  }, [
    filteredData?.divisionId,
    filteredData?.monthYearData,
    selectedStaffData,
  ]);

  useEffect(() => {
    dispatch(fetchDivisionDataCreator());
    return () => {
      dispatch(expenseReportsActions.setYearlyReport([]));
      dispatch(expenseReportsActions.resetFiltersTA());
      dispatch(expenseReportsActions?.setSelectedStaffData([]));
      setSelectedStaff([]);
    };
  }, []);

  useEffect(() => {
    dispatch(expenseReportsActions.setYearlyReport([]));
    dispatch(expenseReportsActions?.setSelectedStaffData([]));
    dispatch(approveExpenseStateActions?.setStaffData([]));
    setSelectedStaff([]);
  }, [isActive]);

  utilYe.editStaffHandler = editStaffHandler;
  utilYe.handleChangeDivisionId = handleChangeDivisionId;
  utilYe.handleChangeMonthYear = handleChangeMonthYear;
  utilYe.downloadExcel = downloadExcel;
  utilYe.onStaffChange = onStaffChange;
  utilYe.handleOnClickGo = handleOnClickGo;
  utilYe.generatePDF = generatePDF;
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-around'}}>
        <Grid container spacing={0.5} sx={{m: 0.5, width: '87%'}}>
          <Grid item xs={4}>
            <Box>
              <Box sx={{margin: 1}}>
                <Typography className={classes.label}>
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Division}
                </Typography>
              </Box>
              <FormControl required fullWidth size="small">
                <InputLabel id="division-dropdown">
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Division}
                </InputLabel>
                <Select
                  inputProps={{
                    'data-testid': 'dropdown-division',
                  }}
                  labelId="division-dropdown"
                  id="division-dropdown"
                  size="small"
                  value={filteredData?.divisionId || []}
                  title={
                    divisionData
                      .filter((division: any) =>
                        filteredData?.divisionId?.includes(division?.value),
                      )
                      ?.map((division: any) => division.label)
                      .join(',') || ''
                  }
                  label="Division*"
                  MenuProps={MenuProp}
                  onChange={(event: any) =>
                    handleChangeDivisionId(event?.target?.value, 'divisionId')
                  }
                  multiple
                  renderValue={selected => (
                    <div style={style.change}>
                      <span>
                        {filteredData?.divisionId
                          .map((division: any, i: any) => {
                            return (
                              selected.includes(division) &&
                              `${Name(
                                division,
                                divisionData,
                                'label',
                                'value',
                              )} `
                            );
                          })
                          .join(',') || ''}
                      </span>
                    </div>
                  )}>
                  {(divisionData || [])?.map((divisionValue: any) => {
                    return (
                      <MenuItem
                        value={divisionValue?.value}
                        key={divisionValue?.value}
                        disabled={
                          filteredData?.divisionId
                            ? filteredData?.divisionId.length === 10 &&
                              !filteredData?.divisionId.includes(
                                divisionValue?.value,
                              )
                            : false
                        }>
                        <CustomMenuItem
                          checked={filteredData?.divisionId?.some(
                            (o: any) => o == divisionValue?.value,
                          )}
                          label={divisionValue?.label}
                          value={divisionValue?.value}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box>
              <Box sx={{margin: 1}}>
                <Typography className={classes.label}>
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Date}
                </Typography>
              </Box>
              <FormControl required size="small" fullWidth>
                <InputLabel id="month-dropdown">
                  {strings.ModuleSpecificUtilitie.expenseReportPage.mmyy}
                </InputLabel>
                <Select
                  id="month-dropdown"
                  size="small"
                  inputProps={{
                    'data-testid': 'dropdown-month',
                  }}
                  labelId="month-dropdown"
                  value={filteredData?.monthYearData || []}
                  title={
                    monthYearData
                      .filter((monthValue: any) =>
                        filteredData?.monthYearData?.includes(
                          monthValue?.month,
                        ),
                      )
                      ?.map((monthvalue: any) =>
                        monthvalue !== 0
                          ? `${monthvalue.monthDesc}-${monthvalue?.year}`
                          : `${monthvalue?.monthDesc}`,
                      )
                      .join(',') || ''
                  }
                  label="mm/yy*"
                  onChange={(event: any) => {
                    handleChangeMonthYear(
                      event?.target?.value,
                      'monthYearData',
                    );
                  }}
                  renderValue={selected => (
                    <div style={style.change}>
                      {monthYearData
                        .filter((monthYear: any) =>
                          selected.includes(monthYear.month),
                        )
                        .map((monthYear: any) => {
                          return (
                            selected?.includes(monthYear?.month) &&
                            `${monthYear.monthDesc}-${monthYear.year}
                          `
                          );
                        })
                        .filter((element: any) => Boolean(element))
                        .join(',')}
                    </div>
                  )}
                  multiple>
                  <MenuItem value={0} key={0}>
                    <CustomMenuItem
                      checked={
                        filteredData?.monthYearData?.length ===
                        monthYearData.length
                      }
                      label="Select All"
                      value={0}
                    />
                  </MenuItem>
                  {(monthYearData || [])?.map((monthValue: any) => (
                    <MenuItem key={monthValue?.month} value={monthValue.month}>
                      <CustomMenuItem
                        checked={filteredData?.monthYearData?.some(
                          (o: number) => o === monthValue?.month,
                        )}
                        label={`${monthValue?.monthDesc}-${monthValue?.year}`}
                        value={monthValue.month}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <Box sx={{margin: 1}}>
                <Typography className={classes.label}>
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Staff}
                </Typography>
              </Box>
              <SelectStaff
                filteredData={filteredData}
                onStaffChange={onStaffChange}
                selectedStaff={selectedStaff}
                selectedStaffData={selectedStaffData}
                type="yearly">
                <SearchComponent staffEditHandler={editStaffHandler} />
                {selectedStaff.map((staff: any) => (
                  <MenuItem
                    key={staff?.staffPositionId}
                    value={staff?.staffPositionId}>
                    <Checkbox
                      checked={selectedStaffData?.some(
                        (o: any) => o == staff?.staffPositionId,
                      )}
                    />
                    {`${staff?.fullName}-${staff?.employeeCode}`}
                  </MenuItem>
                ))}
                {staffData
                  ?.filter(
                    (staff: any) =>
                      !selectedStaffData.includes(staff.staffPositionId),
                  )
                  ?.map((staffs: any) => (
                    <MenuItem
                      key={staffs?.staffPositionId}
                      value={staffs?.staffPositionId}>
                      <Checkbox
                        checked={selectedStaffData?.some(
                          (o: any) => o == staffs?.staffPositionId,
                        )}
                      />
                      {`${staffs?.fullName}-${staffs?.employeeCode}`}
                    </MenuItem>
                  ))}
              </SelectStaff>
            </Box>
          </Grid>
          <Grid item xs={0.5} marginTop={4.2}>
            <DebouncedButton
              data-testid="button-go"
              variant="contained"
              size="large"
              onClick={handleOnClickGo}
              disabled={validated}>
              {strings.Go}
            </DebouncedButton>
          </Grid>
        </Grid>
      </div>
      {open && reportData.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            height: 80,
            marginBottom: -10,
            marginTop: 50,
          }}>
          <Button
            data-testid="download-pdf"
            variant="contained"
            onClick={generatePDF}
            style={{width: 150, margin: 5}}>
            {strings.ModuleSpecificUtilitie.DownloadPdf}
          </Button>
          <Button
            data-testid="download-excel"
            variant="contained"
            sx={{marginLeft: 2}}
            onClick={downloadExcel}
            style={{width: 150, margin: 5}}>
            {strings.ModuleSpecificUtilitie.ExportToExcel}
          </Button>
        </div>
      )}
    </>
  );
};

export default YearlySummaryHeader;
