import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SearchIcon from '@mui/icons-material/Search';
import {SEARCH_OBJECT} from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import {MenuProp, useStyles} from '@app/screens/Styles/style';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {expenseReportsActions} from '@app/screens/module-specific-utilities/pages/expense-reports/redux';
import {CustomMenuItem} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseHeader';
import React, {useEffect, useState} from 'react';
import {
  approveExpenseStateActions,
  fetchDivisionDataCreator,
  fetchStaffDataCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {fetchExpenseReportCreator} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/slice';
import {saveAs} from 'file-saver';
import Excel from 'exceljs';
import {
  tableRowKeys,
  tableFooterKeys,
} from '@app/screens/module-specific-utilities/components/expense-reports/TA-summary/TASummaryReport';
import {getHeaderStyles} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseTable';
import {isEqual} from 'lodash';
import {withPreventDoubleClick} from '@app/components/hoc';
import {style} from '@app/screens/module-specific-utilities/components/expense-reports/yearly-disbursement-summary/yearlySummaryHeader';
import {strings} from '@app/common/strings';

export const selectedStaffAndEmployeeCode = (
  selectedStaff: any,
  value: any,
  key: string,
) => {
  const filteredSelectedStaff = selectedStaff.filter((staff: any) =>
    value.includes(staff.divisionId),
  );
  const selectedEmployeeCode = filteredSelectedStaff.map(
    (staff: any) => staff[key],
  );

  return {filteredSelectedStaff, selectedEmployeeCode};
};

export const renderComponent = (
  selected: any,
  selectedStaff: any,
  string: any,
) => {
  return (
    <div
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 200,
      }}>
      {' '}
      {selectedStaff.map(
        (value: any) =>
          selected?.some((id: any) =>
            string === 'TA'
              ? id == value?.employeeCode
              : id === value.staffPositionId,
          ) &&
          `${value?.fullName}-${value?.employeeCode}${
            selected.indexOf(value?.employeeCode) === selected.length - 1
              ? ''
              : ', '
          }`,
      )}
    </div>
  );
};

export const toolTip = (selectedStaff: any[], selectedStaffData: any) => {
  return selectedStaff
    .filter((value: any) =>
      selectedStaffData?.filter((id: any) => id === value?.employeeCode),
    )
    .map((value: any) => `${value?.fullName}-${value?.employeeCode}`)
    .join(', ');
};

export const SearchComponent: React.FC<{
  staffEditHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({staffEditHandler}) => {
  return (
    <TextField
      size="small"
      style={{width: '100%'}}
      autoFocus
      placeholder="Type to search..."
      InputProps={{
        style: {
          height: 30,
          fontSize: 11,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={staffEditHandler}
      onKeyDown={e => {
        if (e.key !== 'Escape') {
          e.stopPropagation();
        }
      }}
    />
  );
};

export const SelectStaff: React.FC<{
  filteredData: any;
  selectedStaffData: any;
  selectedStaff: any;
  onStaffChange: (e: any) => void;
  type: string;
}> = ({
  children,
  filteredData,
  selectedStaffData,
  selectedStaff,
  onStaffChange,
  type,
}) => {
  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="demo-simple-State">{`Staff`}</InputLabel>
      <Select
        inputProps={{
          'data-testid': 'dropdown-staff',
        }}
        labelId="staff"
        id="staff"
        disabled={!filteredData?.divisionId}
        label="Staff"
        size="small"
        multiple
        MenuProps={MenuProp}
        value={selectedStaffData || []}
        title={toolTip(selectedStaff, selectedStaffData)}
        onChange={onStaffChange}
        renderValue={(selected: any) =>
          renderComponent(selected, selectedStaff, type)
        }>
        {children}
      </Select>
    </FormControl>
  );
};

export const utilTA: any = {
  staffEditHandler: null,
  changeDivisionId: null,
  changeMonthYears: null,
  onStaffChange: null,
  handleClickGo: null,
};

export const getPreviousYearMonths = () => {
  const currentDate = new Date();
  const result = [];

  for (let i = 0; i < 12; i++) {
    const month = currentDate.getMonth() - i;
    const year =
      month < 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    const adjustedMonth = (month + 12) % 12;
    const monthDesc = new Date(year, month, 1).toLocaleString('en-US', {
      month: 'short',
    });

    result.push({
      monthDesc,
      year,
      month: adjustedMonth + 1,
    });
  }

  return result;
};

const tableHeaderData = {
  id: 'S. No.',
  staffName: 'Staff Name',
  divName: 'Division',
  designation: 'Designation',
  hqName: 'HQ',
  month: 'Month',
  distance: 'Distance',
  taClaimed: 'TA Claimed',
  flmCclamied: 'Manager Passed',
  adminPassed: 'Admin Passed',
  difference: 'Difference',
};

const tableHeaderDataProp: string[] = Object.keys(tableHeaderData);
const tableHeaderDataVal: string[] = Object.values(tableHeaderData);

const tableHeaderDataSummary = {
  sNo: null,
  staffName: null,
  designation: null,
  hqName: null,
  month: 'Month',
  distance: 'Distance',
  taClaimed: 'TA Claimed',
  flmCclamied: 'Manager Passed',
  adminPassed: 'Admin Passed',
  difference: 'Difference',
};

const tableHeaderDataPropSummary: string[] = Object.keys(
  tableHeaderDataSummary,
);
const tableHeaderDataValSummary: any[] = Object.values(tableHeaderDataSummary);

const resNestObj = (tableData: any) => {
  return tableData.map((tableObj: any) =>
    tableHeaderDataProp.map((key: any) => {
      if (
        key === 'id' &&
        (tableObj.section === 'Total' || tableObj.section === 'G. Total')
      ) {
        return null;
      } else if (key == 'month' && tableObj.month === null) {
        return tableObj.section;
      } else {
        return tableObj[key];
      }
    }),
  );
};

const resNestObjSummary = (tableData: any) => {
  return tableData.map((tableObj: any) =>
    tableHeaderDataPropSummary.map((key: any, index) => {
      if (key == 'month' && tableObj.month === null) {
        return tableObj.section;
      } else if (index === 2 && key === 'staffName') {
        return 'MONTHLY SUMMARY';
      } else {
        return tableObj[key];
      }
    }),
  );
};

const TASummaryReportHeader: React.FC<{setReport: any}> = ({setReport}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [validated, setValidated] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedStaff, setSelectedStaff] = useState<any[]>([]);
  const filterData = useSelector(
    expenseReportsStateSelector.getFiltersTASummary(),
  );

  const divisionData = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const selectedStaffData = useSelector(
    expenseReportsStateSelector?.getSelectedStaffData(),
  );

  const monthYearData = getPreviousYearMonths();

  const staffData: SEARCH_OBJECT[] = useSelector(
    approveExpenseStateSelector?.getStaffData(),
  );

  const reportData: any = useSelector(
    expenseReportsStateSelector.getReportData(),
  );

  const isActive = useSelector(
    expenseReportsStateSelector.getIsActiveTaSummary(),
  );

  const DebouncedButton = withPreventDoubleClick(Button, 400);

  const searchDebouncedStaff = (criteria: string | any[]) => {
    if (filterData?.divisionId && criteria?.length > 0) {
      dispatch(
        fetchStaffDataCreator({
          searchData: criteria,
          divisionIds: filterData?.divisionId,
          InActive: !isActive,
        }),
      );
    }
  };

  const staffEditHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === undefined || e === null) return;
    if (e?.target?.value?.length >= 3) searchDebouncedStaff(e?.target?.value);
  };

  const changeDivisionId = (value: number[], type: string) => {
    const {filteredSelectedStaff, selectedEmployeeCode} =
      selectedStaffAndEmployeeCode(selectedStaff, value, 'employeeCode');
    if (value.length <= 10) {
      dispatch(
        expenseReportsActions.setFiltersTA({
          [type]: value,
        }),
      );
      dispatch(approveExpenseStateActions?.setStaffData([]));

      if (!isEqual(selectedStaff, filteredSelectedStaff)) {
        setSelectedStaff(filteredSelectedStaff);
        dispatch(
          expenseReportsActions?.setSelectedStaffData(selectedEmployeeCode),
        );
      }
    }
    dispatch(expenseReportsActions?.setReportData([]));
  };

  const changeMonthYears = function (values: any[], type: string) {
    values.forEach((element: number) => {
      if (
        element === 0 &&
        filterData?.monthYearData?.length === monthYearData.length
      ) {
        dispatch(
          expenseReportsActions?.setFiltersTA({
            [type]: [],
          }),
        );
      } else if (element === 0) {
        dispatch(
          expenseReportsActions?.setFiltersTA({
            [type]: monthYearData.map((obj: any) => Object.values(obj)[2]),
          }),
        );
      } else {
        dispatch(
          expenseReportsActions?.setFiltersTA({
            [type]: values,
          }),
        );
      }
    });
    if (values.length <= 0) {
      dispatch(
        expenseReportsActions.setFiltersTA({
          [type]: values,
        }),
      );
    }
  };

  const updateStaff = (event: any, prevSelectedStaff: any) => {
    return prevSelectedStaff.filter((staff: {employeeCode: any}) =>
      event.target.value.includes(staff.employeeCode),
    );
  };
  const condition = (updatedSelectedStaff: any, staff: any) =>
    !updatedSelectedStaff.some(
      (s: {employeeCode: number}) => s.employeeCode === staff.employeeCode,
    );
  const onStaffChange = (event: any) => {
    setSelectedStaff(prevSelectedStaff => {
      // Remove deselected staff codes
      const updatedSelectedStaff = updateStaff(event, prevSelectedStaff);
      // Add newly selected staff codes
      staffData.forEach(staff => {
        if (
          event.target.value.includes(staff.employeeCode) &&
          condition(updatedSelectedStaff, staff)
        ) {
          updatedSelectedStaff.push(staff);
        }
      });

      return updatedSelectedStaff;
    });
    dispatch(expenseReportsActions?.setSelectedStaffData(event.target.value));
  };

  const division = reportData?.header?.divName;
  const period = reportData?.header?.period?.replace(/,\s*$/, '');

  const addHeaderContent = (doc: any, division: any, period: any) => {
    const lineSpacing = 6;
    doc.text('MONTH WISE TA CLAIM VS TA ADMIN PASS', 100, 12);
    doc.setFontSize(11);
    doc.text('Division: ' + division, 10, 20 + lineSpacing);
    const periodText = 'Period: ' + period;
    const periodLines = doc.splitTextToSize(periodText, 250);
    periodLines.forEach((line: any, index: number) => {
      doc.text(line, 10, 20 + (2 + index) * lineSpacing);
    });
  };

  const applyTableStyles = (doc: any, data: any, options: any) => {
    doc.autoTable({
      head: [tableHeaderDataVal],
      body: data,
      theme: 'grid',
      headStyles: getHeaderStyles(7),
      styles: {
        fontSize: 5,
        halign: 'center',
        textColor: [1, 1, 1, 1],
      },
      willDrawCell: (row: any, data: any, body: any) => {
        if (
          row.row.raw[4] === 'Total' ||
          row.row.raw[4] === 'G. Total' ||
          row.row.raw[4] === 'Month'
        ) {
          doc.setTextColor(0, 0, 0);
        }
      },
      ...options,
    });
  };

  const getLineWidthStyles = (data: any) => {
    if (data.row.index === 0 && data.column.index === 0) {
      data.cell.styles.lineWidth = {
        bottom: 0,
        top: 0.5,
        left: 0.5,
        right: 0,
      };
    } else if (data.row.index === 0 && data.column.index !== 0) {
      data.cell.styles.lineWidth = {
        bottom: 0,
        top: 0.5,
        left: 0,
        right: 0,
      };
    }
  };
  const didParseCell = function (data: any, dataSummary: any) {
    if (data.row.raw[1] === 'MONTHLY SUMMARY' && data.column.index === 1) {
      data.cell.styles.fontStyle = 'normal';
    }

    if ([0, 1, 2, 3].includes(data.column.index)) {
      data.column.minWidth = 30;

      if (data.column.index !== 0) {
        data.cell.styles.lineWidth = {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        };
      }

      if (
        data.column.index !== 0 &&
        data.row.index === dataSummary?.length - 1
      ) {
        data.cell.styles.lineWidth = {
          bottom: 0.5,
          top: 0,
          left: 0,
          right: 0,
        };
      }

      if (
        data.column.index === 0 &&
        data.row.index === dataSummary?.length - 1
      ) {
        data.cell.styles.lineWidth = {
          bottom: 0.5,
          top: 0,
          left: 0.5,
          right: 0,
        };
      } else if (
        data.column.index === 0 &&
        data.row.index !== dataSummary?.length - 1
      ) {
        data.cell.styles.lineWidth = {
          bottom: 0,
          top: 0,
          left: 0.5,
          right: 0,
        };
      }
      getLineWidthStyles(data);
    }
  };

  const applySummaryTableStyles = (doc: any, dataSummary: any) => {
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: [tableHeaderDataValSummary],
      body: dataSummary,
      theme: 'grid',
      headStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0, 0.1],
        fillColor: [255, 255, 255],
        textColor: [1, 1, 1, 1],
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 'bold',
        halign: 'center',
      },
      styles: {
        fontSize: 5,
        halign: 'center',
        textColor: [1, 1, 1, 1],
      },
      willDrawCell: (row: any, data: any, body: any) => {
        if (row.row.raw[4] === 'G. Total' || row.row.raw[4] === 'Month') {
          doc.setFontSize(7);
          doc.setTextColor(1, 1, 1);
        }
      },
      didParseCell: (data: any) => didParseCell(data, dataSummary),
    });
  };

  const setPageFooter = (doc: any, pageCount: any, date: any) => {
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(8);
      doc.text(date, 14, doc.internal.pageSize.height - 10);
      doc.text(
        `Page ${i} of ${pageCount}`,
        264,
        doc.internal.pageSize.height - 10,
      );
    }
  };

  const generatePDF = () => {
    const doc: any = new jsPDF('l', 'mm', 'a4');
    const data = resNestObj(reportData?.row);
    const dataSummary = resNestObjSummary(reportData?.footer);
    dataSummary[1][1] = 'MONTHLY SUMMARY';
    const options = {
      margin: {top: 48, left: 10},
      startY: 60,
    };

    addHeaderContent(doc, division, period);
    applyTableStyles(doc, data, options);
    applySummaryTableStyles(doc, dataSummary);

    const pageCount = doc.internal.getNumberOfPages();
    const date = new Date();
    const minutes = date.getMinutes();
    const dateStr = `${date.toLocaleDateString()} ${date.getHours()}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;

    setPageFooter(doc, pageCount, dateStr);

    doc.save('TA Summary Report.pdf');
  };

  const getRowValues = (tableRowKeys: any, rowData: any) => {
    return tableRowKeys.map((key: any) =>
      key === 'month' && rowData.month === null
        ? rowData.section
        : rowData[key],
    );
  };
  const fitToColumnExcelJs = (
    worksheet: any,
    data: any,
    tableRowKeys: string[],
    divisor?: number,
  ) => {
    let index = 1;
    for (const key of tableRowKeys) {
      const maxColumnWidth = Math.max(
        key ? key.toString().length * 10 : 0,
        ...data.map((obj: any) =>
          obj[key] ? obj[key].toString().length * 10 : 0,
        ),
      );
      if (key === 'travelItems') {
        worksheet.getColumn(index).width = 120;
        worksheet.getColumn(index).alignment = {wrapText: true};
      } else {
        worksheet.getColumn(index).width = maxColumnWidth / (divisor ?? 7);
      }
      index++;
    }
  };

  const downloadExcel = () => {
    const workbook = new Excel.Workbook();

    const worksheet = workbook.addWorksheet(
      strings.ModuleSpecificUtilitie.expenseReportPage.TA_Summary,
    );
    let currentRowIdx = worksheet.rowCount;
    const divisionRow = worksheet.addRow([`Division: ${division}`]);
    currentRowIdx = worksheet.rowCount;
    let endColumnIdx = worksheet.columnCount;
    worksheet.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);
    divisionRow.getCell(1).alignment = {horizontal: 'left'};
    const periodRow = worksheet.addRow([`Period: ${period}`]);
    currentRowIdx = worksheet.rowCount;
    endColumnIdx = worksheet.columnCount;
    worksheet.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);
    periodRow.getCell(1).alignment = {horizontal: 'left'};
    worksheet.addRow([]);
    const header = [
      'S. No.',
      'Staff Name',
      'Division',
      'Designation',
      'HQ',
      'Month',
      'Distance',
      'TA Claimed',
      'Manager Passed',
      'Admin Passed',
      'Difference',
    ];
    worksheet.addRow(header);
    reportData?.row?.forEach((rowData: any) => {
      const rowValues = getRowValues(tableRowKeys, rowData);
      if (rowData.section === 'G. Total' || rowData.section === 'Total') {
        currentRowIdx = worksheet.rowCount;
        rowValues.splice(0, 1, null);
        worksheet.addRow(rowValues);
        worksheet.getRow(currentRowIdx + 1).font = {bold: true};
      } else {
        worksheet.addRow(rowValues);
      }
    });

    worksheet.getRow(4).font = {bold: true};
    worksheet.getRow(4).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'C0C0C0'},
    };

    worksheet.addRow([]);
    worksheet.addRow(['MONTHLY SUMMARY']);
    worksheet.addRow([]);
    const headerMonthly = [
      '',
      '',
      '',
      '',
      'Month',
      'Distance',
      'TA Claimed',
      'Manager Passed',
      'Admin Passed',
      'Difference',
    ];

    worksheet.addRow(headerMonthly);
    currentRowIdx = worksheet.rowCount;
    worksheet.getRow(currentRowIdx).font = {bold: true};
    worksheet.getRow(currentRowIdx).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'C0C0C0'},
    };

    reportData?.footer?.forEach((rowData: any, index: number) => {
      const rowValues = tableFooterKeys.map(key =>
        key === 'month' && rowData.month === null
          ? rowData.section
          : rowData[key],
      );
      if (rowData.section === 'G. Total' || rowData.section === 'Total') {
        currentRowIdx = worksheet.rowCount;
        worksheet.addRow(['', '', '', '', ...rowValues]);
        worksheet.getRow(currentRowIdx + 1).font = {bold: true};
      } else {
        worksheet.addRow(['', '', '', '', ...rowValues]);
      }
    });

    fitToColumnExcelJs(
      worksheet,
      [...headerMonthly, ...(reportData?.row ?? '')],
      tableRowKeys,
    );

    workbook.xlsx
      .writeBuffer()
      .then(buffer => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileName = `TASummary.xlsx`;
        saveAs(blob, fileName, {autoBom: false});
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleOnClickGo = () => {
    dispatch(
      fetchExpenseReportCreator({
        divId: filterData?.divisionId,
        period: monthYearData
          .filter(monthYear =>
            filterData.monthYearData.includes(monthYear.month),
          )
          .map((monthYear: any) => ({
            month: monthYear.month,
            year: monthYear.year,
          })),
        empCodes: selectedStaffData
          .map((element: string) => ({
            sapCode: element,
          }))
          .filter((ele: any) => Boolean(ele)),
        InActive: !isActive,
      }),
    );
    setReport(true);
    setOpen(true);
  };

  const validation = () => {
    if (
      filterData?.divisionId?.length !== 0 &&
      filterData?.monthYearData?.length !== 0 &&
      filterData?.monthYearData !== undefined
    ) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  useEffect(() => {
    validation();
  }, [filterData?.divisionId, filterData?.monthYearData, selectedStaffData]);

  useEffect(() => {
    dispatch(fetchDivisionDataCreator());
    return () => {
      dispatch(expenseReportsActions.resetFiltersTA());
      dispatch(expenseReportsActions.setReportData(null));
      dispatch(expenseReportsActions?.setSelectedStaffData([]));
      setSelectedStaff([]);
    };
  }, []);
  useEffect(() => {
    dispatch(expenseReportsActions.setReportData(null));
    dispatch(expenseReportsActions?.setSelectedStaffData([]));
    dispatch(approveExpenseStateActions?.setStaffData([]));
    setSelectedStaff([]);
  }, [isActive]);

  utilTA.staffEditHandler = staffEditHandler;
  utilTA.changeDivisionId = changeDivisionId;
  utilTA.changeMonthYears = changeMonthYears;
  utilTA.onStaffChange = onStaffChange;
  utilTA.handleClickGo = handleOnClickGo;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
        }}>
        <Grid container spacing={0.5} sx={{m: 0.5, width: '87%'}}>
          <Grid item xs={4}>
            <Box>
              <Box sx={{margin: 1}}>
                <Typography className={classes.label}>
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Division}
                </Typography>
              </Box>
              <FormControl
                data-testid={'division-select'}
                required
                fullWidth
                size="small">
                <InputLabel id="division-dropdown">
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Division}
                </InputLabel>
                <Select
                  data-testid="division-select"
                  inputProps={{
                    'data-testid': 'dropdown-division',
                  }}
                  labelId="division-dropdown"
                  id="division-dropdown"
                  size="small"
                  value={filterData?.divisionId || []}
                  title={
                    divisionData
                      .filter((division: any) =>
                        filterData?.divisionId?.includes(division?.value),
                      )
                      ?.map((division: any) => division.label)
                      .join(',') || ''
                  }
                  label="Division*"
                  MenuProps={MenuProp}
                  onChange={(event: any) =>
                    changeDivisionId(event?.target?.value, 'divisionId')
                  }
                  multiple
                  renderValue={selected => (
                    <div style={style.change}>
                      {divisionData
                        .filter((division: any) =>
                          selected.includes(division.value),
                        )
                        .map((division: any) => `${division.label}`)
                        .join(',') || ''}
                    </div>
                  )}>
                  {(divisionData || [])?.map((currentDivisionValue: any) => {
                    return (
                      <MenuItem
                        value={currentDivisionValue?.value}
                        key={currentDivisionValue?.value}
                        disabled={
                          filterData?.divisionId
                            ? filterData?.divisionId.length === 10 &&
                              !filterData?.divisionId.includes(
                                currentDivisionValue?.value,
                              )
                            : false
                        }>
                        <CustomMenuItem
                          checked={filterData?.divisionId?.some(
                            (val: any) => val == currentDivisionValue?.value,
                          )}
                          label={currentDivisionValue?.label}
                          value={currentDivisionValue?.value}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box>
              <Box sx={{m: 1}}>
                <Typography className={classes.label}>
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Date}
                </Typography>
              </Box>
              <FormControl fullWidth required size="small">
                <InputLabel>
                  {strings.ModuleSpecificUtilitie.expenseReportPage.mmyy}
                </InputLabel>
                <Select
                  id="month-dropdown"
                  labelId="month-dropdown"
                  size="small"
                  inputProps={{
                    'data-testid': 'dropdown-month',
                  }}
                  label="mm/yy*"
                  onChange={(event: any) => {
                    changeMonthYears(event?.target?.value, 'monthYearData');
                  }}
                  value={filterData?.monthYearData || []}
                  title={
                    monthYearData
                      .filter((monthValue: any) =>
                        filterData?.monthYearData?.includes(monthValue?.month),
                      )
                      ?.map((monthvalue: any) =>
                        monthvalue !== 0
                          ? `${monthvalue.monthDesc}-${monthvalue?.year}`
                          : `${monthvalue?.monthDesc}`,
                      )
                      .join(',') || ''
                  }
                  multiple
                  renderValue={selected => (
                    <div style={style.change}>
                      {monthYearData
                        .filter(monthYear => selected.includes(monthYear.month))
                        .map((monthYear: any) => {
                          return (
                            selected?.includes(monthYear?.month) &&
                            `${monthYear.monthDesc}-${monthYear.year}
                        `
                          );
                        })
                        .filter((element: any) => Boolean(element))
                        .join(',')}
                    </div>
                  )}>
                  <MenuItem value={0} key={0}>
                    <CustomMenuItem
                      checked={
                        filterData?.monthYearData?.length ===
                        monthYearData.length
                      }
                      label="Select All"
                      value={0}
                    />
                  </MenuItem>
                  {(monthYearData || [])?.map((monthValue: any) => (
                    <MenuItem key={monthValue?.month} value={monthValue.month}>
                      <CustomMenuItem
                        value={monthValue.month}
                        checked={filterData?.monthYearData?.some(
                          (o: number) => o === monthValue?.month,
                        )}
                        label={`${monthValue?.monthDesc}-${monthValue?.year}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Box sx={{margin: 1}}>
                <Typography className={classes.label}>
                  {strings.ModuleSpecificUtilitie.expenseReportPage.Staff}
                </Typography>
              </Box>
              <SelectStaff
                filteredData={filterData}
                onStaffChange={onStaffChange}
                selectedStaff={selectedStaff}
                selectedStaffData={selectedStaffData}
                type="TA">
                <SearchComponent staffEditHandler={staffEditHandler} />
                {selectedStaff.map((staffVal: any) => (
                  <MenuItem
                    key={staffVal?.staffPositionId}
                    value={staffVal?.employeeCode}>
                    <Checkbox
                      checked={selectedStaffData?.some(
                        (o: any) => o == staffVal?.employeeCode,
                      )}
                    />
                    {`${staffVal?.fullName}-${staffVal?.employeeCode}`}
                  </MenuItem>
                ))}
                {staffData
                  ?.filter(
                    (staff: any) =>
                      !selectedStaffData.includes(staff.employeeCode),
                  )
                  .map((staffVal: any) => (
                    <MenuItem
                      key={staffVal?.staffPositionId}
                      value={staffVal?.employeeCode}>
                      <Checkbox
                        checked={selectedStaffData?.some(
                          (o: any) => o == staffVal?.employeeCode,
                        )}
                      />
                      {`${staffVal?.fullName}-${staffVal?.employeeCode}`}
                    </MenuItem>
                  ))}
              </SelectStaff>
            </Box>
          </Grid>
          <Grid item xs={0.5} marginTop={4.2}>
            <DebouncedButton
              data-testid="button-go"
              variant="contained"
              size="large"
              onClick={handleOnClickGo}
              disabled={validated}>
              {strings.Go}
            </DebouncedButton>
          </Grid>
        </Grid>
      </div>
      {open && reportData?.row?.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            height: 80,
            marginBottom: -10,
            marginTop: 50,
          }}>
          <Button
            data-testid="download-pdf"
            variant="contained"
            sx={{marginLeft: 2}}
            onClick={generatePDF}
            style={{width: 150, margin: 5}}>
            {strings.ModuleSpecificUtilitie.DownloadPdf}
          </Button>
          <Button
            data-testid="download-excel"
            variant="contained"
            onClick={downloadExcel}
            style={{width: 150, margin: 5}}>
            {strings.ModuleSpecificUtilitie.ExportToExcel}
          </Button>
        </div>
      )}
    </>
  );
};

export default TASummaryReportHeader;
