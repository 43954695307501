import {
  Button,
  CircularProgress,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import {
  useCallback,
  useState,
  useMemo,
  memo,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {
  uploadMiscExpenseDataCreator,
  miscSaveCreator,
  fetchBilDetailsCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {ViewBillPopup} from './sub-components/ViewBillPopup';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {
  dailyDataGrid,
  nameStyles,
  referColor,
  useStyles,
} from '@app/screens/Styles/style';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../approve-expenses/styles/ApproveExpenseMiscEditableGridStyle.css';
import UploadBillPopup from './BillPopup/BillDialog';
import theme from '@app/themes';
import {styles} from './styles/customStyle';
import {strings} from '@app/common/strings';
import {MiscellaneousExpenseType} from './types/approve-expense';

export const NumberInput = memo(
  forwardRef((props: any, ref) => {
    const [value, setValue] = useState<any>(props?.value);
    let hasError = false;
    const refInput = useRef(null);
    if (
      (['Unlock Deduction', 'DA Adjustment', 'TA Adjustment'].includes(
        props.data.expenseType,
      ) &&
        parseInt(value) < 0) ||
      (parseInt(value) > 0 &&
        !['Unlock Deduction'].includes(props?.data?.expenseType))
    ) {
      hasError = true;
    }
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },

        isCancelBeforeStart() {
          return false;
        },
      };
    });

    return (
      <input
        ref={refInput}
        value={value}
        onChange={event => {
          let val = event?.target?.value?.substring(
            0,
            event?.target?.value?.length - (event?.target?.value?.length - 6),
          );
          if (
            props?.data?.expenseType == strings.TAAdjustment ||
            props?.data?.expenseType == strings.DAAdjustment
          ) {
            val = val?.replace(/[^0-9-]/g, '');
          } else if (props?.data?.expenseType == strings.UnlockDeduction) {
            val =
              val?.startsWith('-') || val?.startsWith('0')
                ? val?.replace(/[^0-9-]/g, '')
                : '';
          } else {
            val = val?.replace(/\D/g, '');
          }
          setValue(val);
        }}
        className={`number-input ${!hasError && 'number-input-error'}`}
      />
    );
  }),
);

export const util: any = {
  onViewBillClickfuntion: null,
  renderUploadCell: null,
  convertBase64: null,
  changeHandlers: null,
  uploadButton: null,
  colorChange: null,
  viewBill: null,
  onRowValueChanged: null,
  style: null,
  resonValidation: null,
  request: null,
  filterArr: null,
  condtionCheck: null,
};

export const NoRecordsFoundBox = () => (
  <Box
    sx={{
      border: 2,
      mb: 5,
    }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 10,
        mb: 10,
      }}>
      <Typography>{strings.noRecordFound}</Typography>
    </Box>
  </Box>
);

const toolTipWrapper = (cellName: any) => (
  <Tooltip title={cellName}>
    <span>{cellName}</span>
  </Tooltip>
);

const ApproveExpenseMiscEditableGrid = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expenseId, setExpenseId] = useState('');
  const [billPopup, setBillPopup] = useState(false);
  const [staffPositionId, setStaffPositionId] = useState('');
  const miscEditedRowColorIndex =
    useSelector(approveExpenseStateSelector.getMiscEditedRowColorIndex()) ?? [];
  const expenseApprovalsMisc: any[] =
    useSelector(approveExpenseStateSelector?.getMiscExpenseApprovalsData()) ??
    [];
  const status = useSelector(
    approveExpenseStateSelector.getApproveExpensestatus(),
  );
  const [rowData, setRowData] = useState<any>();
  const rowVal = () => {
    let datagrid = expenseApprovalsMisc?.map(({...rest}) => ({
      ...rest,
      ['isEditable']: status?.expenseStatusId === 9,
    }));
    return datagrid;
  };

  useEffect(() => {
    setRowData(rowVal());
  }, [expenseApprovalsMisc, status]);
  const onViewBillClickfuntion = (params: any) => {
    const miscExpenseParamsData = {...params?.data};
    let request = {
      id: params?.data?.expMiscHistoyId,
      page: 'misc',
    };
    setExpenseId(params?.data?.expMiscHistoyId);
    dispatch(fetchBilDetailsCreator(request));
    dispatch(approveExpenseStateActions.setViewBillPopupVisible(true));
    dispatch(approveExpenseStateActions.setMiscExpense(miscExpenseParamsData));
  };
  const uploadButton = (params: any) => {
    const miscExpenseParamsData = {...params?.data};
    setBillPopup(true);
    setExpenseId(params?.data?.expMiscHistoyId);
    setStaffPositionId(params?.data?.staffPositionId);
    dispatch(approveExpenseStateActions.setUploadBillPopupVisible(true));
    dispatch(approveExpenseStateActions.setMiscExpense(miscExpenseParamsData));
  };

  const viewBill = (props: any) => {
    if (
      props?.data?.expenseType?.toLocaleLowerCase() !==
        MiscellaneousExpenseType.UnlockDeduction &&
      props?.data?.isBillsUploaded
    ) {
      return (
        <Button
          size="small"
          variant="contained"
          data-testid={`ViewBillClick`}
          onClick={() => onViewBillClickfuntion(props)}>
          {strings.view}
        </Button>
      );
    } else {
      return null;
    }
  };

  const renderUploadCell = (params: any) => {
    if (
      params?.data?.expenseType?.toLocaleLowerCase() !==
        MiscellaneousExpenseType.UnlockDeduction &&
      params?.data?.isBusinessExpenditure
    ) {
      return (
        <label htmlFor="contained-button-file1">
          <Input
            accept=".pdf,.png,.jpg"
            id="contained-button-file1"
            data-testid={`upInput-${params?.data?.expenseTypeId}`}
            type="file"
            multiple
            onChange={changeHandlers}
            disabled={status?.expenseStatusId !== 9}
          />
          <Button
            variant="contained"
            component="span"
            data-testid="uploadButton"
            size="small"
            onClick={() => uploadButton(params)}
            disabled={status?.expenseStatusId !== 9}>
            {strings.upload}
          </Button>
        </label>
      );
    }
    return <></>;
  };

  const style = (params: any) => {
    if (status?.expenseStatusId == 9) {
      if (miscEditedRowColorIndex?.includes(params?.data?.expMiscHistoyId)) {
        return {backgroundColor: theme.colors.red[1300]};
      }
      return {backgroundColor: theme.colors.yellow[700]};
    }
  };
  const style1 = (params: any) => {
    if (status?.expenseStatusId == 9) {
      return {backgroundColor: theme.colors.yellow[700]};
    }
  };
  const renderExpenseType = (params: any) => {
    return (
      <Tooltip title={params?.data?.expenseType || ''}>
        <span>{params?.data?.expenseType}</span>
      </Tooltip>
    );
  };

  const isUnlockDeductionRow = (params: any) =>
    params?.data?.expenseType?.toLocaleLowerCase() !==
      MiscellaneousExpenseType.UnlockDeduction && status?.expenseStatusId === 9;

  const columns: any = [
    {
      field: 'expenseType',
      headerName: 'EXPENSE TYPE',
      width: 170,
      pinned: true,
      maxWidth: 300,
      editable: false,
      cellRenderer: renderExpenseType,
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      width: 130,
      pinned: true,
      maxWidth: 200,
      editable: false,
      cellRenderer: (params: any) => {
        return params?.data?.amount ? params?.data?.amount : '';
      },
    },
    {
      field: 'amountClaimed',
      headerName: 'AMOUNT CLAIMED',
      width: 150,
      editable: false,
      maxWidth: 200,
      cellRenderer: (params: any) => {
        return params?.data?.amountClaimed ? params?.data?.amountClaimed : '';
      },
    },
    {
      field: 'changeReason',
      headerName: 'CHANGE REASON',
      width: 150,
      editable: false,
      maxWidth: 300,
      cellRenderer: (params: any) => toolTipWrapper(params?.data?.changeReason),
    },
    {
      field: 'amountManagerPassed',
      headerName: 'AMOUNT PASSED BY MANAGER',
      width: 170,
      maxWidth: 200,
      cellRenderer: (params: any) => {
        return params?.data?.amountManagerPassed
          ? params?.data?.amountManagerPassed
          : '';
      },
      editable: false,
    },
    {
      field: 'managerChangeReason',
      headerName: 'CHANGE REASON BY MANAGER',
      width: 150,
      maxWidth: 300,
      editable: false,
      cellRenderer: (params: any) =>
        toolTipWrapper(params?.data?.managerChangeReason),
    },
    {
      field: 'amountAdminPassed',
      headerName: 'AMOUNT PASSED BY ADMIN',
      width: 200,
      editable: (params: any) => isUnlockDeductionRow(params),
      maxWidth: 200,
      cellStyle: (params: any) =>
        params?.data?.expenseType?.toLocaleLowerCase() !==
          MiscellaneousExpenseType.UnlockDeduction && style1(params),
      cellEditor: NumberInput,
    },
    {
      field: 'adminChangeReason',
      headerName: 'CHANGE REASON BY ADMIN',
      width: 200,
      cellDataType: 'text',
      maxWidth: 300,
      cellStyle: (params: any) =>
        params?.data?.expenseType?.toLocaleLowerCase() !==
          MiscellaneousExpenseType.UnlockDeduction && style(params),
      editable: (params: any) => isUnlockDeductionRow(params),
      cellEditor: TextInput,
      cellRenderer: (params: any) =>
        toolTipWrapper(params?.data?.adminChangeReason),
    },
    {
      headerName: 'VIEW',
      field: 'miscBillPath',
      width: 150,
      cellRenderer: viewBill,
      editable: false,
      maxWidth: 200,
    },
    {
      headerName: 'UPLOAD',
      field: 'miscBillPath',
      width: 150,
      cellRenderer: renderUploadCell,
      editable: false,
      maxWidth: 200,
    },
  ];

  function convertBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader?.result);
      reader.readAsDataURL(blob);
    });
  }

  const changeHandlers = (event: any) => {
    void (async () => {
      try {
        const file = event?.target?.files;
        let upload = false;
        if (file !== undefined) {
          const data = new FormData();
          data?.append('expenseId', expenseId);
          data?.append('StaffpositionId', staffPositionId);
          data?.append('ExpenseCategory', 'MISC');
          for (let row of file) {
            if (row?.size < 3145728) {
              upload = true;
              data?.append('Files', row);
              data?.append('UploadedFileName', row?.name);
              const base64 = await convertBase64(row);
              data?.append('FilesBase64String', String(base64));
            } else {
              ToasterService.showToaster(
                strings.moduleSpecificUtilities.greaterThan3MB,
                ToastType.ERROR,
              );
            }
          }
          if (upload) {
            const tempArr = [...expenseApprovalsMisc];
            const index = tempArr?.findIndex(
              element => element?.expMiscHistoyId === expenseId,
            );
            tempArr[index] = {
              ...tempArr[index],
              miscBillPath: event?.target?.files[0]?.name,
            };

            dispatch(
              approveExpenseStateActions?.setMiscExpenseApprovalsData(tempArr),
            );
            dispatch(uploadMiscExpenseDataCreator(data));
          }
        }

        event.target.value = null;
      } catch (error) {}
    })();
  };

  const Input = styled('input')({
    display: 'none',
  });

  function colorChange(params: any) {
    let ChangeRowColor = false;
    if (
      params?.data?.amount &&
      params?.data?.amountClaimed &&
      params?.data?.amount != params?.data?.amountClaimed
    ) {
      return 'highlight';
    }
    if (miscEditedRowColorIndex?.length > 0) {
      miscEditedRowColorIndex?.forEach(function (element: any) {
        if (element === params?.data?.expMiscHistoyId) {
          ChangeRowColor = true;
        }
      });

      return ChangeRowColor && 'Open';
    }
  }

  const getRowId = useMemo(() => {
    return (params: any) => params?.data?.expMiscHistoyId;
  }, []);

  const request = (data: any) => {
    return {
      expMiscHistoyId: data?.expMiscHistoyId,
      StaffPositionId: data?.staffPositionId,
      Month: data?.expenseMonth,
      Year: data?.expenseYear,
      userId: data?.userId,
      adminApprovedMiscRemarks: data?.adminChangeReason,
      adminApprovedAmount: Number(data?.amountAdminPassed),
    };
  };
  const filterArr = (expMiscHistoyId: any, arr: any) => {
    return arr?.filter((user: any) => user !== expMiscHistoyId);
  };

  const condtionCheck = (data: any) =>
    ([
      strings.UnlockDeduction,
      strings.DAAdjustment,
      strings.TAAdjustment,
    ].includes(data?.expenseType) &&
      data?.amountAdminPassed <= 0) ||
    (data?.amountAdminPassed >= 0 &&
      ![strings.UnlockDeduction].includes(data?.expenseType)) ||
    ([strings.DAAdjustment, strings.TAAdjustment].includes(data?.expenseType) &&
      data?.amountAdminPassed >= 0);

  const resaonCon = (expenseApprovalsMisc: any, index: any, data: any) => {
    if (
      expenseApprovalsMisc[index]?.amountAdminPassed == data?.amountAdminPassed
    ) {
      if (miscEditedRowColorIndex.includes(data?.expMiscHistoyId)) {
        let updatedChildren = [...miscEditedRowColorIndex];
        const index = updatedChildren.indexOf(data?.expMiscHistoyId);
        if (index !== -1) {
          updatedChildren.splice(index, 1);
        }
        dispatch(
          approveExpenseStateActions.setMiscEditedRowColorIndex(
            updatedChildren,
          ),
        );
      }
    }
  };

  const resonValidation = (
    data: any,
    index: any,
    expenseApprovalsMisc: any,
  ) => {
    if (
      data?.adminChangeReason &&
      data?.adminChangeReason?.length >= 5 &&
      data?.adminChangeReason?.length <= 50 &&
      expenseApprovalsMisc[index]?.adminChangeReason != data?.adminChangeReason
    ) {
      dispatch(
        approveExpenseStateActions.setMiscEditedRowColorIndex(
          filterArr(data?.expMiscHistoyId, miscEditedRowColorIndex),
        ),
      );
      dispatch(miscSaveCreator(request(data)));
    }
    if (
      !data?.adminChangeReason &&
      expenseApprovalsMisc[index]?.amountAdminPassed != data?.amountAdminPassed
    ) {
      if (!miscEditedRowColorIndex?.includes(data?.expMiscHistoyId)) {
        dispatch(
          approveExpenseStateActions.setMiscEditedRowColorIndex(
            miscEditedRowColorIndex?.concat(data?.expMiscHistoyId),
          ),
        );
      }
      ToasterService.showToaster(
        strings.AdminChangeReasonisMandatory,
        ToastType.ERROR,
      );
    }
    if (data?.adminChangeReason && data?.adminChangeReason?.length < 5) {
      ToasterService.showToaster(
        strings.moduleSpecificUtilities.minMaxChar,
        ToastType.ERROR,
      );
    }
    resaonCon(expenseApprovalsMisc, index, data);
    let save = false;
    if (condtionCheck(data)) {
      save = true;
    }
    if (
      data?.adminChangeReason &&
      data?.adminChangeReason?.length >= 5 &&
      data?.adminChangeReason?.length <= 50 &&
      expenseApprovalsMisc[index]?.amountAdminPassed !=
        data?.amountAdminPassed &&
      save
    ) {
      if (!miscEditedRowColorIndex?.includes(data?.expMiscHistoyId)) {
        dispatch(
          approveExpenseStateActions.setMiscEditedRowColorIndex(
            miscEditedRowColorIndex?.concat(data?.expMiscHistoyId),
          ),
        );
      }
      dispatch(
        approveExpenseStateActions.setMiscEditedRowColorIndex(
          filterArr(data?.expMiscHistoyId, miscEditedRowColorIndex),
        ),
      );
      dispatch(miscSaveCreator(request(data)));
    }
  };
  const onRowValueChanged = useCallback(
    event => {
      const data = event?.data;
      const tempArr = [...expenseApprovalsMisc];
      const index = expenseApprovalsMisc?.findIndex(
        (element: {expMiscHistoyId: any}) =>
          element?.expMiscHistoyId === data?.expMiscHistoyId,
      );
      let save = false;

      if (
        ([
          strings.UnlockDeduction,
          strings.DAAdjustment,
          strings.TAAdjustment,
        ].includes(data?.expenseType) &&
          data?.amountAdminPassed < 0) ||
        (data?.amountAdminPassed > 0 &&
          ![strings.UnlockDeduction].includes(data?.expenseType)) ||
        ([strings.DAAdjustment, strings.TAAdjustment].includes(
          data?.expenseType,
        ) &&
          data?.amountAdminPassed > 0)
      ) {
        save = true;
      }
      if (
        data?.adminChangeReason &&
        data?.adminChangeReason?.length >= 5 &&
        data?.adminChangeReason?.length <= 50 &&
        expenseApprovalsMisc[index]?.amountAdminPassed !=
          data?.amountAdminPassed &&
        save
      ) {
        dispatch(
          approveExpenseStateActions?.setMiscExpenseApprovalsData(tempArr),
        );
        dispatch(
          approveExpenseStateActions.setMiscEditedRowColorIndex(
            filterArr(data?.expMiscHistoyId, miscEditedRowColorIndex),
          ),
        );
        dispatch(miscSaveCreator(request(data)));
      } else {
        resonValidation(data, index, expenseApprovalsMisc);
      }
    },
    [expenseApprovalsMisc, miscEditedRowColorIndex],
  );

  const isRowEditable = (params: any) =>
    params?.data?.expenseType?.toLocaleLowerCase() ===
    MiscellaneousExpenseType.UnlockDeduction;

  util.onViewBillClickfuntion = onViewBillClickfuntion;
  util.renderUploadCell = renderUploadCell;
  util.convertBase64 = convertBase64;
  util.changeHandlers = changeHandlers;
  util.uploadButton = uploadButton;
  util.colorChange = colorChange;
  util.viewBill = viewBill;
  util.onRowValueChanged = onRowValueChanged;
  util.style = style;
  util.resonValidation = resonValidation;
  util.request = request;
  util.filterArr = filterArr;
  util.condtionCheck = condtionCheck;

  return (
    <>
      {expenseApprovalsMisc?.length > 0 && (
        <div>
          <Box sx={dailyDataGrid(510, 75)}>
            <div style={styles.miscBox} className="ag-theme-alpine">
              <AgGridReact
                rowData={rowData}
                columnDefs={columns}
                onRowValueChanged={onRowValueChanged}
                editType={'fullRow'}
                rowHeight={40}
                getRowId={getRowId}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  suppressMovable: true,
                  cellDataType: false,
                  editable: isRowEditable,
                }}
                getRowClass={params =>
                  `super-agetRowClassNamepp-theme--${colorChange(params)}`
                }
              />
            </div>
          </Box>
          <div style={styles.paddingBottom40}>
            {nameColor?.map((item: any) => {
              return (
                <span key={item?.name} style={referColor}>
                  <span
                    key={item?.name}
                    style={nameStyles(`${item?.color}`)}></span>{' '}
                  {item?.name}
                </span>
              );
            })}
          </div>
        </div>
      )}
      {expenseApprovalsMisc?.length <= 0 && <NoRecordsFoundBox />}
      <ViewBillPopup
        open={false}
        onClose={() => setBillPopup(false)}
        expenseId={expenseId}
      />
      <UploadBillPopup
        open={billPopup}
        onClose={() => setBillPopup(false)}
        changeHandlers={changeHandlers}
        uploadDisabled={status?.expenseStatusId !== 9}
        expenseId={expenseId}
      />
    </>
  );
};

export default ApproveExpenseMiscEditableGrid;

export const TextInput = memo(
  forwardRef((props: any, ref) => {
    const [value, setValue] = useState<string | undefined>(props?.value);
    let hasError = true;
    const refInput = useRef(null);
    if (value !== undefined) {
      if (value?.length >= 5 && value?.length <= 50) {
        hasError = false;
      }
    }
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },

        isCancelBeforeStart() {
          return false;
        },
      };
    });

    return (
      <input
        ref={refInput}
        type="text"
        value={value}
        onChange={event => {
          let val = event?.target?.value
            ?.replace(/[^a-zA-Z0-9 ]/g, '')
            ?.trimStart();
          if (val?.length <= 50) {
            setValue(val ?? '');
          }
        }}
        className={`number-input ${hasError && 'number-input-error'}`}
      />
    );
  }),
);
export const nameColor = [
  {
    name: strings.WEEKOFFHOLIDAYLEAVE,
    color: theme.colors.LIGHT_GREEN,
  },
  {
    name: strings.POLICYVIOLATION,
    color: theme.colors.pink[300],
  },
  {
    name: strings.EDITABLEROW,
    color: theme.colors.yellow[700],
  },
];
